import { BreadItemModel } from '@Components/CustomBreadCrumb';
import { ReadOnlyEditor } from '@Components/ReadOnlyEditor';
import { ModuleContext } from '@Context/Module.context';
import { SessionContext } from '@Context/Session.context';
import { useBread } from '@Hooks/breadcrumbs';
import { useUser } from '@Hooks/firebase';
import { ModuleSkeleton } from '@Pages/Home/Session/pages/Module/components/ModuleSkeleton';
import { ModuleTopBar } from '@Pages/Home/Session/pages/Module/components/ModuleTopBar';
import { getUrlForResource } from '@Utils/resource.utils';
import { ResponsiveProps, useBreakpoint } from '@Utils/responsive.utils';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { AuditResult } from './ModuleResults/AuditResult';

const ContentWrapper = styled.div<ResponsiveProps>`
    position: relative;
    max-width: 1076px;
    padding: ${(props) => (props.responsive.isDesktop || props.responsive.isTablet ? '0 32px' : '0 16px')};
`;

const InnerWrapper = styled.div`
    max-width: 710px;
`;

const CoverImageWrapper = styled.div<ResponsiveProps>`
    height: ${(props) => (props.responsive.isDesktop || props.responsive.isTablet ? '200px' : '150px')};
    overflow: hidden;
`;

const AuditContainer = styled.div`
    display: flex;
`;

export const ModuleResults = () => {
    const params = useParams<{
        session_id: string;
        module_id: string;
    }>();

    console.log(params);

    const navigate = useNavigate();
    const bread = useBread();

    const { module, records } = useContext(ModuleContext);
    const { session, refreshModules: loadModule, loadingModules: loading } = useContext(SessionContext);
    const { progress: userProgress, allRecords } = useUser(true);
    const [image, setImage] = useState<string>();

    useEffect(() => {
        if (session?.formation?.cover_image_big_url || module?.cover_image_url || session?.formation?.cover_image_url) {
            getUrlForResource(
                session?.formation?.cover_image_big_url ||
                    session?.formation?.cover_image_url ||
                    module?.cover_image_url
            ).then((res) => {
                setImage(res?.resource_url);
            });
        }
    }, [module?.cover_image_url, session?.formation?.cover_image_big_url, session?.formation?.cover_image_url]);

    // variables

    const progress = useMemo(() => {
        if (!module || !params.session_id) return 0;

        if (module.type !== 'audit') {
            const moduleProgress = userProgress?.filter(
                (p) => p.session_id === params.session_id && p.module_id === module.module_id && p.activity.done
            );

            if (!moduleProgress) return 0;

            return (100 * moduleProgress.length) / module.activities.length;
        } else {
            if (!params.session_id) return 0;

            const totalMaxRecords = Math.min(Math.max(module.min_records, records.length), module.max_records);
            const totalActivities = module.activities.length * totalMaxRecords;

            const completedActivities = records.reduce((acc, record) => {
                return acc + record.answers.length;
            }, 0);

            return (100 * completedActivities) / totalActivities;
        }
    }, [module, params.session_id, userProgress, records]);

    // Lifecycle
    useEffect(() => {
        console.log(session);
        loadModule(session).then((res) => {
            console.log('loadmodule', res);
            if (!res) return;
            bread.updateTree((prev: BreadItemModel[]) => {
                const module = res.find((m) => m.module_id === params.module_id);

                if (!module) return prev;

                return [
                    {
                        url: `/session/${params.session_id}`,
                        label: `Session`,
                        id: 'session',
                    },
                    {
                        url: `/session/${params.session_id}/module/${params.module_id}`,
                        label: module.title,
                        id: params.module_id,
                    },
                    {
                        url: `/session/${params.session_id}/module/${params.module_id}/results`,
                        label: `Résultats`,
                        id: params.module_id + 'results',
                    },
                ];
            });
        });
    }, [params.module_id, params.session_id, session]);

    // functions
    function onBack() {
        navigate(`/session/${params.session_id}/module/${params.module_id}`);
    }

    const responsive = useBreakpoint();

    return !loading && module ? (
        module.type === 'audit' && allRecords ? (
            <div className="w-full h-full">
                <CoverImageWrapper
                    responsive={responsive}
                    className="bg-gray-400 w-full"
                    style={{ backgroundImage: `url(${image})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
                ></CoverImageWrapper>
                <ModuleTopBar module={module} progress={progress} />
                <ContentWrapper responsive={responsive} className="m-auto mt-5 pb-5">
                    <div className="he-paragraph--regular gray-500">
                        <span className=" cursor-pointer" onClick={onBack}>
                            <i className="pi pi-arrow-left mr-2" />
                            Retour à la liste des dossiers
                        </span>
                    </div>
                    <InnerWrapper className="mt-5">
                        <div className="he-header--h1 gray-900">{module.title}</div>
                        {module.description && (
                            <div className="quill-custom mt-5">
                                <ReadOnlyEditor content={module.description || null} />
                            </div>
                        )}
                        <AuditContainer className="w-full mt-5 flex flex-column">
                            <AuditResult module={module} records={records} allRecords={allRecords} />
                        </AuditContainer>
                    </InnerWrapper>
                </ContentWrapper>
            </div>
        ) : (
            <Navigate to={`/session/${params.session_id}`} />
        )
    ) : (
        <ModuleSkeleton />
    );
};
