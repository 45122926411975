import { Module } from '@Types/Module';
import { AuditRecord } from '@Types/User';
import { createContext } from 'react';

type RecordLayoutContextType = {
    module: Module;
    record: AuditRecord;
};

export const RecordLayoutContext = createContext({} as RecordLayoutContextType);

export const RecordLayoutProvider: React.FC<React.PropsWithChildren<{ module: Module; record: AuditRecord }>> = ({
    module,
    record,
    children,
}) => {
    return <RecordLayoutContext.Provider value={{ module, record }}>{children}</RecordLayoutContext.Provider>;
};
