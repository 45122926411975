import React from 'react';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import { fr } from 'suneditor/src/lang';

import './ReadOnlyEditor.scss';

export type ReadOnlyEditorProps = {
    content: string | null;
};
export const ReadOnlyEditor: React.FC<ReadOnlyEditorProps> = (props) => {
    return (
        <div className="readonly w-full">
            <SunEditor
                defaultValue={props.content || ''}
                width="100%"
                setDefaultStyle="font-family: Roboto; font-size: 12px;"
                setOptions={{
                    height: 'auto',
                    minHeight: '20px',
                    lang: fr,
                    buttonList: [],
                }}
                readOnly
                disable
                disableToolbar
                hideToolbar
            />
        </div>
    );
};
