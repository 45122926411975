import React from "react";
import {DateTime} from "luxon";
import {Button} from "primereact/button";
import {Line} from "@Components/Line";
import {SessionExtended, SessionParticipant} from "@Types/Session";
import {useNavigate, useParams} from "react-router-dom";
import {useBread} from "@Hooks/breadcrumbs";
import {useToast} from "@Hooks/toast";
import { Module } from "@Types/Module";

export type SessionEndDialogProps = {
	module?: Module;
	session: SessionExtended<false>,
	dpcLoading: boolean,
	participant: SessionParticipant,
	onDownloadDPC: () => Promise<void>
}
export const SessionEndDialog: React.FC<SessionEndDialogProps> = (props) => {

	const params = useParams<{session_id: string, activity_id: string, module_id: string}>();
	const bread = useBread();
	const navigate = useNavigate();

	const {info} = useToast();

	return (
		<div className="w-full h-full">
			<div className="he-header--h1 text-center">
				Félicitations ! 👏
			</div>
			<div className="he-paragraph--text text-center mt-4">
				Vous venez de terminer votre formation
			</div>
			<div className="he-paragraph--text text-center mt-3">
				Vous pouvez dès à présent télécharger votre attestation de suivi DPC.
				La formation et son contenu restent accessibles jusqu’au {props.session && DateTime.fromISO(props.session.end_date).toFormat("dd/MM/yyyy")}.
			</div>
			<Button
				label="Télécharger l'attestation de suivi DPC et répondre au questionnaire de satisfaction"
				className="he-button--primary--lg mt-4 w-full"
				disabled={props.participant.manual_attestation_lock}
				onClick={async () => {
					info("Génération de l'attestation de suivi DPC en cours, veuillez patienter ...");
					await props.onDownloadDPC();
					bread.updateTree(prev => [{
						id: params.session_id,
						url: `/session/${params.session_id}`,
						label: props.session.formation.title
					}, {
						id: "satisfaction",
						url: `/session/${params.session_id}/satisfaction`,
						label: "Questionnaire de satisfaction"
					}])
					navigate(`/session/${props.session.session_id}/satisfaction`);
				}}
			/>
			{
				props.module && props.module.type==="audit" && props.module.audit_type === "final" && (
					<Button 
						label="Consulter vos résultats"
						className="he-button--secondary--lg mt-2 w-full"
						disabled={props.dpcLoading}
						onClick={() => navigate(`/session/${props.session.session_id}/module/${props.module?.module_id}/results`)}
					/>
				)
			}
			<Line height={1} className="bg-gray-200 my-4"/>
			<div className="flex justify-content-end">
				<Button label="Retour à l'accueil" className="he-button--secondary-variant-nfb--md"
				        onClick={() => navigate(`/session/${props.session.session_id}`)}/>
			</div>
		</div>
	)
}
