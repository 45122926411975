import styled from 'styled-components';

export const AnswerItem = styled.div<{ is_correct?: boolean; is_selected?: boolean }>`
    background: ${(props) => {
        return props.is_correct === undefined
            ? props.is_selected
                ? 'var(--primary-7)'
                : 'white'
            : props.is_correct
            ? '#EEFBF4'
            : '#FDEEED';
    }};
    border: ${(props) => {
        return props.is_correct === undefined
            ? props.is_selected
                ? '1px solid var(--primary-100)'
                : '1px solid #D0D5DD'
            : props.is_correct
            ? '1px solid #2ECC71'
            : '1px solid #E74C3C';
    }};
    border-radius: 8px;
    user-select: none;
    padding: 14px;
    min-height: 48px;
`;
