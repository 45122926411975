import styled from 'styled-components';

type Props = {
    checked: boolean | undefined;
    className?: string;
    onChange: () => void;
};
export const CustomRadioButton = (props: Props) => {
    return (
        <CheckBox onClick={props.onChange} className={(props.className, props.checked ? 'checked' : '')}>
            {props.checked && <div className="inner-checked" />}
        </CheckBox>
    );
};

const CheckBox = styled.div`
    --outer-size: 16px;
    --inner-size: calc(var(--outer-size) - 6px);

    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--outer-size);
    height: var(--outer-size);
    min-width: var(--outer-size);
    min-height: var(--outer-size);
    max-width: var(--outer-size);
    max-height: var(--outer-size);
    background: #fff;
    line-height: 17px;
    border: 1px solid #d9d9d9;
    &.checked {
        border: 1px solid var(--primary-100);
    }
    border-radius: 50%;
    cursor: pointer;
    transition: all 150ms;
    box-sizing: border-box;
    &:hover {
        background: #f2f2f2;
    }

    .inner-checked {
        width: var(--inner-size);
        height: var(--inner-size);
        border-radius: 50%;
        background: var(--primary-100);
    }
`;
