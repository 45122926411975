import { Line } from '@Components/Line';
import { useUser } from '@Hooks/firebase';
import { useToast } from '@Hooks/toast';
import { Activity, QuizzActivity } from '@Types/Activity';
import { AuditRecord } from '@Types/User';
import { firestore } from '@Utils/config/firebase';
import { doc, onSnapshot, updateDoc } from 'firebase/firestore';
import produce from 'immer';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { CustomActivityStepper } from '../components/CustomActivityStepper';
import { RecordLayoutContext } from '../layouts/RecordLayoutProvider';
import { RecordActivityPreview } from './components/RecordActivityPreview';
import { useBreakpoint } from '@Utils/responsive.utils';
import { classNames } from 'primereact/utils';
import { SessionTrackerContext } from '@Context/SessionTracker.context';

export const RecordActivityPage: React.FC = () => {
    const { module, record } = useContext(RecordLayoutContext);
    const {saveTime} = useContext(SessionTrackerContext);
    const [loading, setLoading] = useState(false);
    const [liveRecord, setLiveRecord] = useState(record);

    const { error } = useToast('top-right', 3000);
    const [visibleActivities, setVisibleActivities] = useState<string[]>([]);
    const user = useUser(true);

    const refs = useRef<Record<string, React.RefObject<HTMLDivElement>>>({});

    useEffect(() => {
        return onSnapshot(doc(firestore.db, 'users', user.meta.user_id, 'records', record.record_id), (doc) => {
            setLiveRecord(doc.data() as AuditRecord);
        });
    }, [record.record_id, user.meta.user_id]);

    const updateSelection = useCallback(
        async (activity_id: string, selection: number[]) => {
            setLoading(true);
            const answerIndex = liveRecord.answers.findIndex((r) => r.activity_id === activity_id);

            const updated = produce(liveRecord, (draft) => {
                if (answerIndex === -1) {
                    draft.answers.push({
                        activity_id,
                        answer: selection[0] as 0 | 1 | 2,

                    });
                } else {
                    draft.answers[answerIndex].answer = selection[0] as 0 | 1 | 2;
                }

                return draft;
            });

            try {
                await updateDoc(doc(firestore.db, 'users', user.meta.user_id, 'records', record.record_id), updated);
                saveTime()
            } catch (err) {
                console.error(err);
                error("Erreur lors de l'enregistrement de la réponse");
            } finally {
                setLoading(false);
            }
        },
        [liveRecord, record.record_id, user.meta.user_id]
    );


    const updateAnswerDetail = useCallback(
        async (activity_id: string, detail: string) => {
            setLoading(true);
            const answerIndex = liveRecord.answers.findIndex((r) => r.activity_id === activity_id);
            if (answerIndex === -1) return;
            const updated = produce(liveRecord, (draft) => {
                draft.answers[answerIndex].detail = detail;
                return draft;
            });

            try {
                await updateDoc(doc(firestore.db, 'users', user.meta.user_id, 'records', record.record_id), updated);
                saveTime()
            } catch (err) {
                console.error(err);
                error("Erreur lors de l'enregistrement de la réponse");
            } finally {
                setLoading(false);
            }
        },
    [error, liveRecord, record.record_id, user.meta.user_id])


    const observerOptions = {
        root: null,
        rootMargin: '0px',
        threshold: 1.0,
    };

    const observers = useRef<Record<string, IntersectionObserver>>({});

    useEffect(() => {
        const observersCpy = observers.current;
        for (const key in refs.current) {
            if (refs.current[key].current) {
                observers.current[key] = new IntersectionObserver((entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            setVisibleActivities((prev) => Array.from(new Set([...prev, key])));
                        } else {
                            setVisibleActivities((prev) => Array.from(new Set(prev.filter((k) => k !== key))));
                        }
                    });
                }, observerOptions);
                observers.current[key].observe(refs.current[key].current);
            }
        }

        return () => {
            for (const key in observersCpy) {
                observersCpy[key]?.disconnect();
            }
        };
    }, [liveRecord.record_id]);

    const components = useMemo(() => {
        return (module.activities.filter((a) => a.type === 'ucq') as (QuizzActivity & Activity)[]).map((a) => {
            const activityId = a?.activity_id;
            if (!activityId) return <></>;

            let ref: React.RefObject<HTMLDivElement> | undefined = refs.current[activityId];

            if (!ref) {
                ref = refs.current[activityId] = React.createRef();
            }

            return (
                <RecordActivityPreview
                    ref={ref}
                    key={liveRecord.record_id+"_"+a.activity_id}
                    record={liveRecord}
                    activity={a}
                    index={0}
                    onClick={(activity, selection) => {
                        if (!activity.activity_id) return;
                        updateSelection(activity.activity_id, selection);
                    }}
                    onUpdateAnswerDetail={(activity, detail) => {
                        if (!activity.activity_id) return;
                        console.log("updateAnswerDetail", liveRecord, activity, detail);
                        updateAnswerDetail(activity.activity_id, detail);
                    }}
                />
            );
        });
    }, [liveRecord, module.activities, updateAnswerDetail, updateSelection]);

    useEffect(() => {
        // scroll to first activity
        if (components.length > 0) {
            const id = setTimeout(() => {
                const firstActivity = module.activities[0];
                if (firstActivity.activity_id)
                    refs.current[firstActivity.activity_id].current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }, 100)
            return () => {
                clearTimeout(id);
            }
        }
        return () => {}
    }, [liveRecord.record_id, module.activities]);

    const events = useMemo(() => {
        return module.activities
            .map((a) => {
                if (a.type === 'ucq' && a.activity_id) {
                    return {
                        activity_id: a.activity_id,
                        title: a.title,
                        icon: 'pi pi-check',
                        color: 'blue',
                        done: liveRecord.answers.some((r) => r.activity_id === a.activity_id),
                        visible: visibleActivities.includes(a.activity_id),
                    };
                }
                return null;
            })
            .filter((a) => !!a);
    }, [liveRecord.answers, module.activities, visibleActivities]);

    const markerTemplate = (item: {
        activity_id: string;
        title: string;
        icon: string;
        color: string;
        done: boolean;
        visible: boolean;
    }) => {
        return (
            <CustomStep
                done={item.done}
                focused={item.visible}
                onClick={() => {
                    refs.current[item.activity_id].current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }}
                title={item.title}
            >
                {item.done && <i className="pi pi-check" />}
            </CustomStep>
        );
    };

    const ref = useRef<HTMLDivElement>();

    const responsive = useBreakpoint();

    useEffect(() => {
        ref.current = document.getElementsByClassName('RecordActivitySide')?.[0] as HTMLDivElement;
    }, [responsive]);

    return (
        <div className="flex">
            {ref.current &&
                createPortal(
                    <div className="h-full">
                        <CustomActivityStepper value={events} marker={markerTemplate} className="h-full" />
                    </div>,
                    ref.current
                )}
            <div
                className={classNames(responsive.isDesktop && 'ml-5', 'gap-2 h-full w-full flex-column flex')}
                key={record.record_id}
            >
                <div className="he-header--h2">
                    {liveRecord.meta.name} {loading && <i className="pi pi-spin pi-spinner" />}
                </div>
                <Line height={2} className="bg-gray-300" />
                <div className="w-full flex flex-column gap-2">{components}</div>
            </div>
        </div>
    );
};

const CustomStep = styled.div<{
    done: boolean;
    focused: boolean;
}>`
    --size: ${(props) => (props.focused ? '20px' : '10px')};
    &:hover {
        --size: ${(props) => (props.focused ? '20px' : '15px')};
    }
    cursor: pointer;
    width: var(--size);
    height: var(--size);
    min-height: var(--size);
    min-width: var(--size);
    border-radius: 50%;
    border: 2px solid ${(props) => (props.done ? 'var(--color-light-green)' : 'var(--primary-100)')};
    background-color: ${(props) =>
        props.focused ? (props.done ? 'var(--color-light-green)' : 'var(--primary-100)') : 'transparent'};
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;
    i {
        ${(props) => (props.focused ? '' : 'display: none;')}
        font-size: 12px;
        color: ${(props) => (props.done ? (props.focused ? 'white' : 'var(--color-light-green)') : 'transparent')};
    }
`;
