import { SessionContext } from '@Context/Session.context';
import { SessionTrackerContext } from '@Context/SessionTracker.context';
import { useApi } from '@Hooks/api';
import { useBread } from '@Hooks/breadcrumbs';
import { useUser } from '@Hooks/firebase';
import { usePromise } from '@Hooks/promise';
import { useToast } from '@Hooks/toast';
import { Formation } from '@Types/Formation';
import { Module } from '@Types/Module';
import { SessionExtended, SessionParticipant } from '@Types/Session';
import { downloadUint8 } from '@Utils/download.utils';
import { getLastActivity, isSessionFinished, isSessionStarted } from '@Utils/progress.utils';
import { getBlobForResource } from '@Utils/resource.utils';
import { ResponsiveProps, useBreakpoint } from '@Utils/responsive.utils';
import { DateTime, Interval } from 'luxon';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import React, { useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { CustomDialog } from '@Components/CustomDialog';

const OuterWrapper = styled.div<ResponsiveProps>`
    ${(props) =>
        props.responsive.isDesktop
            ? `
	  position: absolute;
	  transform: translateY(-50%);
	  top: 0;
	  right: 0;
      width: 364px;
	  z-index: 2;
  `
            : `
      width: 100%;
      max-width: 800px;
  `}
    margin: auto;
`;

const CardWrapper = styled.div`
    border-radius: 14px;
`;

export type SessionInnerCardProps = {
    session: SessionExtended<false>;
    formation: Formation;
    modules: Module[];
    participants: SessionParticipant<false>[];
};
export const SessionInnerCard: React.FC<SessionInnerCardProps> = (props) => {
    const { meta, progress, allRecords } = useUser(true);

    const navigate = useNavigate();
    const { updateTree } = useBread();
    const { error } = useToast();

    const { timeSinceDownload, report } = useContext(SessionTrackerContext);
    const { participant } = useContext(SessionContext);
    const [showConfirmDownload, setShowConfirmDownload] = useState<boolean>(false);

    const onStart = () => {
        const firstModule = props.formation.units[0].modules_ids[0];
        navigate(`/session/${props.session.session_id}/module/${firstModule}`);
    };

    const getNextUnitText = () => {
        const units = props.session.unitsConfig;

        const today = DateTime.now();

        const matchingUnit = units.filter((unit) => {
            if (!unit.start_date || !unit.end_date) return false;
            const interval = Interval.fromDateTimes(DateTime.fromISO(unit.start_date), DateTime.fromISO(unit.end_date));
            return interval.contains(today);
        });

        if (matchingUnit.length > 0) return 'Reprendre la formation';

        const nextUnit = units.find((unit) => {
            if (!unit.start_date) return false;
            return DateTime.fromISO(unit.start_date).diff(today).as('seconds') > 0;
        });

        if (!nextUnit) return 'Reprendre la formation';

        return (
            <div>
                La formation reprendra le <br />
                {DateTime.fromISO(nextUnit.start_date!).setLocale('FR-fr').toFormat('dd LLLL yyyy')}
            </div>
        );
    };

    const onContinue = () => {
        if (!progress || !participant || !report || timeSinceDownload === null || !allRecords) return;

        const res = getLastActivity(
            allRecords,
            progress,
            props.session,
            props.modules,
            participant,
            report,
            timeSinceDownload,
            error
        );

        if (res && !res.module_id) {
            error('Une erreur est survenue lors de la récupération de la dernière activité');
            return;
        }


        if (res && res.module_id && res.activity_id === undefined) {
            updateTree(() => {
                return [
                    {
                        id: props.session.session_id,
                        url: `/session/${props.session.session_id}?u=${props.session.formation.units.length - 1}`,
                        label: props.session.formation.title,
                    },
                    {
                        id: res.module_id,
                        url: `/session/${props.session.session_id}/module/${res.module_id}`,
                        label: props.modules.find((m) => m.module_id === res.module_id)?.title,
                    },
                ];
            })
            navigate(`/session/${props.session.session_id}/module/${res.module_id}`);
        }

        if (!res) return;

        const sessionUrl = `/session/${props.session.session_id}`;
        const module = props.modules.find((m) => m.module_id === res.module_id);
        if (!module) return;

        const moduleUrl = `${sessionUrl}/module/${res.module_id}`;

        const activity = module.activities.find((a) => a.activity_id === res.activity_id);

        if (!activity) return;

        const activityUrl = `${moduleUrl}/activity/${res.activity_id}`;

        updateTree((prev) => {
            return [
                {
                    id: props.session.session_id,
                    url: sessionUrl,
                    label: props.session.formation.title,
                },
                {
                    url: moduleUrl,
                    id: module.module_id,
                    label: module.title,
                },
                {
                    url: activityUrl,
                    id: activity.activity_id,
                    label: activity.title,
                },
            ];
        });
        navigate(`/session/${props.session.session_id}/module/${res.module_id}/activity/${res.activity_id}`);
    };

    const remainingTime = useMemo(() => {
        if (participant) {
            const diff = DateTime.fromISO(participant.end_date).diff(DateTime.now(), ['day', 'hours', 'minutes']);

            const day = diff.get('days');
            const hours = diff.get('hours');

            const daySection = day > 0 ? `${day.toString().padStart(2, '0')} j ` : '';
            const hoursSection = hours > 0 ? `${hours.toString().padStart(2, '0')} h` : '';

            return daySection + hoursSection;
        }
        return undefined;
    }, [participant]);

    const [downloadFile, loading] = usePromise(async () => {
        if (!props.session) {
            setShowConfirmDownload(false);
            return;
        }

        try {
            const [res, blob] = await getBlobForResource(props.session.formation.educational_support_url);

            if (!res?.resource_url || !blob) return;
            const a = document.createElement('a');

            // download file from url using fetch
            const file = new File([blob], res.resource_name, { type: 'application/data' });
            const url = window.URL.createObjectURL(file);
            a.href = url;
            a.download = res.resource_name;
            a.click();
            setShowConfirmDownload(false);
        } catch (e) {
            console.error(e);
            error('Une erreur est survenue lors du téléchargement du support pédagogique');
        }
    });

    const api = useApi();

    const [onDownloadDPC, dpcLoading] = usePromise(async () => {
        if (props.session && !participant?.manual_attestation_lock) {
            const res = await api.session_call_getAttestation({
                queries: [
                    {
                        session_id: props.session.session_id,
                        user_ids: [meta.user_id],
                    },
                ],
            });

            if (res.result !== 'ok') throw new Error(res.result);

            if (res.mode === 'single') {
                await downloadUint8(
                    res.file,
                    `Attestation suivi formation - ${props.session.formation.andpc} - Session ${props.session.session_custom_id} - ${meta.lastname} ${meta.firstname}.pdf`,
                    'application/pdf'
                );
            }
        }
    });

    const responsive = useBreakpoint();

    return (
        <OuterWrapper className="flex flex-column right-0" responsive={responsive}>
            {showConfirmDownload && (
                <CustomDialog visible onHide={() => setShowConfirmDownload(false)} width={400}>
                    <div className="he-header--h2 mb-3 text-center">Téléchargement du support pédagogique</div>
                    <div className="he-paragraph--regular-16 gray-500 mb-4 text-center">
                        Ce support pédagogique a pour seul objectif de faciliter la compréhension et l’apprentissage des
                        vidéos suivantes, sa lecture ne sera pas prise en compte dans le suivi de cette formation.{' '}
                        <br />
                        <br /> Pour suivre la formation, cliquez sur "Formation"
                    </div>
                    <div className="flex flex-column gap-2 w-full">
                        <Button
                            className="he-button--primary--md"
                            onClick={downloadFile}
                            loading={loading}
                            disabled={loading}
                        >
                            <i className="pi pi-download" />
                            Télécharger le support
                        </Button>
                        <Button
                            className="he-button--secondary-variant--md"
                            onClick={() => setShowConfirmDownload(false)}
                        >
                            Formation
                        </Button>
                    </div>
                </CustomDialog>
            )}
            <CardWrapper className="w-full p-4 bg-white flex flex-column">
                {(responsive.isTablet || responsive.phone) && (
                    <div className="he-header--h2 mb-3">{props.formation.title}</div>
                )}
                {isSessionFinished(participant) ? (
                    <Button className="he-button--primary--lg w-full mb-3" disabled>
                        Formation terminée, le contenu de la formation n’est plus accessible
                    </Button>
                ) : isSessionStarted(props.session, progress, allRecords) ? (
                    <Button className="he-button--primary--md w-full mb-3" onClick={onStart}>
                        <i className="pi pi-caret-right" style={{ fontSize: '14px' }} />
                        Commencer la formation
                    </Button>
                ) : (
                    <Button className="he-button--primary--md w-full mb-3" onClick={onContinue}>
                        <i className="pi pi-arrow-circle-right" style={{ fontSize: '16px' }} />
                        {getNextUnitText()}
                    </Button>
                )}

                {!isSessionFinished(participant) && remainingTime && (
                    <div className="w-full flex he-paragraph--regular gray-400 mb-3 align-items-center">
                        <i className="pi pi-clock mr-2" />
                        Fin de la formation dans : {remainingTime}
                    </div>
                )}
                {!isSessionFinished(participant) && participant && (
                    <div className="w-full flex he-paragraph--regular gray-400 mb-3">
                        <i className="pi pi-calendar mr-2" style={{ marginTop: 2 }} />
                        <div className="flex flex-column">
                            <span>
                                La formation se termine le{' '}
                                {DateTime.fromISO(props.session.end_date).setLocale('FR-fr').toFormat('dd LLLL yyyy')}
                            </span>
                            {!responsive.isDesktop && (
                                <div className="mt-2 flex flex-column">
                                    {props.session.unitsConfig.map((u, i) => {
                                        return i < props.session?.unitsConfig.length - 1 ? (
                                            <span className="mb-2" key={i}>
                                                &bull; La partie {i + 1} se termine le{' '}
                                                {u.end_date ? DateTime.fromISO(u.end_date).toFormat('DDD') : ' - '}
                                            </span>
                                        ) : (
                                            <span className="mb-2" key={i}>
                                                &bull; La partie {i + 1} commence le{' '}
                                                {u.start_date ? DateTime.fromISO(u.start_date).toFormat('DDD') : ' - '}{' '}
                                                et se termine le{' '}
                                                {u.end_date ? DateTime.fromISO(u.end_date).toFormat('DDD') : ' - '}
                                            </span>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    </div>
                )}
                <Button
                    loading={dpcLoading}
                    disabled={
                        participant === undefined ||
                        !participant?.attestation_access ||
                        participant?.manual_attestation_lock
                    }
                    className="he-button--primary-nf--md w-full mb-2"
                    onClick={onDownloadDPC}
                >
                    <i className="pi pi-download" />
                    Télécharger l'attestation de suivi DPC
                </Button>
                {(participant === undefined || !participant?.attestation_access) && (
                    <div className="he-paragraph--regular gray-500 text-center w-full mt-2">
                        Attestation disponible à l'issue de la formation
                    </div>
                )}
            </CardWrapper>
            <Button
                className={classNames(
                    'mt-3',
                    responsive.isDesktop || responsive.small
                        ? 'he-button--secondary-nf--xs'
                        : 'he-button--secondary--xs'
                )}
                onClick={() => setShowConfirmDownload(true)}
                loading={loading}
                disabled={!props.session.formation.educational_support_url}
            >
                <i className="pi pi-download" />
                {!props.session.formation.educational_support_url
                    ? 'Aucun support pédagogique'
                    : 'Télécharger le support pédagogique'}
            </Button>
        </OuterWrapper>
    );
};
