import { Line } from '@Components/Line';
import { ModuleContext } from '@Context/Module.context';
import { SessionTrackerContext } from '@Context/SessionTracker.context';
import { useApi } from '@Hooks/api';
import { useToast } from '@Hooks/toast';
import { AuditModule } from '@Types/Module';
import { AuditRecord } from '@Types/User';
import { AuditRecordStatus, durationToMillis, getRecordStatus } from '@Utils/module.utils';
import { Button } from 'primereact/button';
import { Knob } from 'primereact/knob';
import { classNames } from 'primereact/utils';
import React, { useContext, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
/*
    "light-green": #2ECC71,
    "light-blue": #3498DB,
    "light-red": #E74C3C,
    "light-orange": #F39C12,
*/
const scale = [
    { value: 10, color: 'light-red', hex: '#E74C3C' },
    { value: 40, color: 'light-orange', hex: '#F39C12' },
    { value: 90, color: 'light-yellow', hex: '#F1C40F' },
    { value: 100, color: 'light-blue', hex: '#3498db' },
];

const getColor = (value: number) => {
    const item = scale.find((i) => value <= i.value);
    return item;
};

type Props = {
    index: number;
    record?: AuditRecord;
    module: AuditModule;
};
export const AuditRecordCard: React.FC<Props> = (props) => {
    const params = useParams<{
        session_id: string;
        module_id: string;
    }>();

    const progress = props.record ? (100 * props.record.answers.length) / props.module.activities.length : 0;
    const navigate = useNavigate();

    const api = useApi();
    const { error, success } = useToast();
    const [loading, setLoading] = React.useState(false);

    const { records } = useContext(ModuleContext);
    const {recordsTimeProgress} = useContext(SessionTrackerContext);

    const onNewRecord = async () => {
        if (!params.session_id) return;

        setLoading(true);
        try {
            const res = await api.session_call_addRecord({
                module_id: props.module.module_id,
                session_id: params.session_id,
            });

            if (res.result === 'ok') {
                success('Dossier patient créé');
                navigate(`/session/${params.session_id}/module/${params.module_id}/record/${res.record.record_id}`);
            } else {
                throw new Error(res.result);
            }
        } catch (e) {
            console.error(e);
            error('Erreur lors de la création du dossier patient');
        } finally {
            setLoading(false);
        }
    };

    const onContinue = () => {
        if (params.session_id && params.module_id && props.module.activities.length > 0)
            navigate(`/session/${params.session_id}/module/${params.module_id}/record/${props.record?.record_id}`);
    };

    const color = getColor(progress);

    const hasFinished = useMemo(() => {
        if (!props.record) return false;
        if (props.record.submitted) return true;
        const recordProgression = recordsTimeProgress[props.record.record_id];
        if (!recordProgression) return false;
        
        let recordRequiredTime = 0;
        if (!props.module.minimal_duration || !props.module.minimal_duration_unit) {
            recordRequiredTime = 0;
        } else {
            recordRequiredTime = durationToMillis(props.module.minimal_duration, props.module.minimal_duration_unit) / props.module.min_records;
        }

        const remainingTime = Math.max(0, recordRequiredTime - recordProgression);

        const status = getRecordStatus({
            record: props.record,
            remainingTime,
            module: props.module as AuditModule,
        });
        return (
            (status & (AuditRecordStatus.TimeCompleted | AuditRecordStatus.ActivitiesCompleted)) ===
            (AuditRecordStatus.TimeCompleted | AuditRecordStatus.ActivitiesCompleted)
        );
    }, [props.module, props.record, recordsTimeProgress]);


    const canCreateNewRecord = useMemo(() => {
        if (props.module.type !== 'audit') return false;
        return records.every((r) => {

            const recordProgression = recordsTimeProgress[r.record_id];
            if (!recordProgression) return false;

            const status = getRecordStatus({
                record: r,
                remainingTime: recordProgression,
                module: props.module as AuditModule,
            });

            return (
                (status & (AuditRecordStatus.TimeCompleted | AuditRecordStatus.ActivitiesCompleted)) ===
                (AuditRecordStatus.TimeCompleted | AuditRecordStatus.ActivitiesCompleted)
            );
        });
    }, [props.module, records, recordsTimeProgress]);

    return (
        <Container
            className={classNames('p-2', !props.record ? 'bg-gray-50' : hasFinished ? 'bg-green-7' : 'bg-primary-7')}
        >
            <div className="he-paragraph--regular--bold gray-700">
                {props.record ? props.record.meta.name : `Dossier patient n°${props.index + 1}`}
            </div>
            <Line height={1} className="bg-gray-300 my-2" />
            <Knob
                value={progress}
                size={90}
                strokeWidth={8}
                min={0}
                max={100}
                showValue={false}
                valueColor={hasFinished ? '#2ecc71' : color?.hex || '#bbb'}
                rangeColor="#bbb"
            />
            <i
                className={classNames(
                    'user-icon',
                    !props.record
                        ? 'pi pi-folder'
                        : !hasFinished
                        ? `pi pi-folder-open color-${color?.color || 'gray-100'} ml-1`
                        : 'pi pi-check-circle color-light-green',
                    'absolute'
                )}
            />
            <Button
                className={classNames(
                    props.record
                        ? !hasFinished
                            ? 'he-button--primary--xxs'
                            : 'he-button--success--xxs'
                        : 'he-button--secondary-variant--xxs'
                )}
                loading={loading}
                disabled={loading || (!canCreateNewRecord && !props.record)}
                onClick={props.record ? onContinue : onNewRecord}
            >
                {props.record ? (!hasFinished ? 'Reprendre' : 'Consulter') : 'Démarrer'}{' '}
                <i
                    className={classNames(
                        props.record ? (!hasFinished ? 'pi pi-chevron-right' : 'pi pi-eye') : 'pi pi-caret-right'
                    )}
                />
            </Button>
        </Container>
    );
};

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid #e0e0e0;

    .user-icon {
        font-size: 36px;
        color: #c2c2c2;
        top: 49%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;
