import { CustomDialog } from '@Components/CustomDialog';
import { Line } from '@Components/Line';
import { useUser } from '@Hooks/firebase';
import { TcsScale } from '@Pages/Home/Session/components/TcsScale';
import { Activity, ActivityHistory, FreeTextActivity, QuizzActivity, QuizzAnswer } from '@Types/Activity';
import { Module } from '@Types/Module';
import { useBreakpoint } from '@Utils/responsive.utils';
import { sanitizeString } from '@Utils/string.utils';
import { FC, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ActivityPreview } from './components/ActivityPreview';

const colors = ['#EA2828', '#EAA828', '#dedc15', '#A3CC2E', '#2ECC71'];

type Props = {
    modules: Module[];
    FC?: boolean;
};
export const FCResults: FC<Props> = (props) => {
    const [selection, setSelection] = useState<{
        activity: Activity & (QuizzActivity | FreeTextActivity);
        history: ActivityHistory;
    } | null>(null);

    const getColor = (score: number) => {
        const index = Math.floor(score * 5);
        return colors[index >= colors.length ? colors.length - 1 : index];
    };

    const { session_id } = useParams<{
        session_id: string;
    }>();

    const { progress } = useUser(true);

    const initialModule = props.modules.find((m) => m.type === 'quizz' && m.quizz_type === 'initial');
    const finalModule = props.modules.find((m) => m.type === 'quizz' && m.quizz_type === 'final');
    const initialModuleAnswers =
        progress?.filter((p) => p.session_id === session_id && p.module_id === initialModule?.module_id) ?? [];
    const finalModuleAnswers =
        progress?.filter((p) => p.session_id === session_id && p.module_id === finalModule?.module_id) ?? [];

    const getIndexesOfText = (text: string, word: string): (readonly [index: number, match: string])[] => {
        const sanitizedText = sanitizeString(text.toLowerCase()).replaceAll(/[\u0300-\u036f]/g, '');
        const sanitizedWord = sanitizeString(word.toLowerCase()).replaceAll(/[\u0300-\u036f]/g, '');
        const regexOkWord = sanitizedWord.replaceAll('*', '(?:\\S+)?');

        console.log([sanitizedText, sanitizedWord, regexOkWord]);

        const regex = new RegExp(`\\b(${regexOkWord})\\b`, 'gi');
        let res;

        const indexes = [];
        while ((res = regex.exec(sanitizedText)) != null) {
            indexes.push([res.index, res[0]] as const);
        }

        return indexes;
    };

    const initialTotalScore = useMemo(() => {
        if (!progress || !initialModule) return null;

        return initialModule.activities
            .filter((a) => a.type === 'ucq' || a.type === 'mcq')
            .map((a) => a as Activity & QuizzActivity)
            .reduce((acc, a) => {
                const totalGoodAnswers = a.answers.filter((a) => a.is_response).length;
                const activityProgress = progress.find(
                    (p) =>
                        p.session_id === session_id &&
                        p.module_id === initialModule.module_id &&
                        p.activity_id === a.activity_id
                );

                let score = 0;

                if (!activityProgress) return acc;

                const userAnswers = activityProgress.activity.answers as QuizzAnswer;

                for (const userAnswer of userAnswers) {
                    if (a.answers[userAnswer].is_response) score++;
                }

                return acc + score / totalGoodAnswers;
            }, 0);
    }, [progress, session_id, initialModule]);

    const finalTotalScore = useMemo(() => {
        if (!progress || !finalModule) return null;

        return finalModule.activities
            .filter((a) => a.type === 'ucq' || a.type === 'mcq')
            .map((a) => a as Activity & QuizzActivity)
            .reduce((acc, a) => {
                const totalGoodAnswers = a.answers.filter((a) => a.is_response).length;
                const activityProgress = progress.find(
                    (p) =>
                        p.session_id === session_id &&
                        p.module_id === finalModule.module_id &&
                        p.activity_id === a.activity_id
                );

                let score = 0;

                if (!activityProgress) return acc;

                const userAnswers = activityProgress.activity.answers as QuizzAnswer;

                for (const userAnswer of userAnswers) {
                    if (a.answers[userAnswer].is_response) score++;
                }

                return acc + score / totalGoodAnswers;
            }, 0);
    }, [progress, session_id, finalModule]);

    const checkIfKeywordInAnswer = (answer: string, orKeywords: string[]) => {
        if (orKeywords.length === 1) {
            const indexesOfMatchingRes = getIndexesOfText(answer ?? '', orKeywords[0]);
            return indexesOfMatchingRes.length > 0;
        } else {
            return orKeywords.some((orKeyword) => {
                const indexesOfMatchingRes = getIndexesOfText(answer ?? '', orKeyword);
                return indexesOfMatchingRes.length > 0;
            });
        }
    };

    const generateActivityReport = (module: Module) => {
        if (!progress) return;

        let totalScore = 0,
            total = 0;

        return (
            <>
                {module.activities
                    .filter((a) => a.type === 'ucq' || a.type === 'mcq' || a.type === 'freetext')
                    .map((a) => a as Activity & (QuizzActivity | FreeTextActivity))
                    .map((activity, i, tab) => {
                        let totalGoodAnswers = 1;
                        let score = 0;

                        const activityProgress = progress.find(
                            (p) =>
                                p.session_id === session_id &&
                                p.module_id === module.module_id &&
                                p.activity_id === activity.activity_id
                        );

                        if (activity.type === 'freetext') {
                            const a = activity as Activity & FreeTextActivity;
                            totalGoodAnswers = a.keywords.length;
                            total += totalGoodAnswers;

                            if (!activityProgress) return null;

                            const keywordsFound = a.keywords.filter((k) => {
                                const orKeywords = k.split('$');
                                return checkIfKeywordInAnswer(activityProgress.activity.answer ?? '', orKeywords);
                            });
                            score = keywordsFound.length;
                            totalScore += score;
                        } else {
                            const a = activity as Activity & QuizzActivity;
                            totalGoodAnswers = a.answers.filter((a) => a.is_response).length;
                            total += totalGoodAnswers;
                            if (!activityProgress) return null;

                            const userAnswers = activityProgress.activity.answers as QuizzAnswer;

                            for (const userAnswer of userAnswers) {
                                if (a.answers[userAnswer].is_response) score++;
                            }
                            totalScore += score;
                        }

                        return (
                            <>
                                <ActivityContainer
                                    key={activity.activity_id}
                                    className="activity-container w-full flex gray-700 align-items-center gap-2"
                                >
                                    <i className="pi pi-question-circle" style={{ fontSize: 12 }} />
                                    <div className="he-paragraph--regular">{activity.title}</div>
                                    <div
                                        className="he-paragraph--small primary-100 cursor-pointer"
                                        onClick={() => {
                                            setSelection({
                                                activity,
                                                history: activityProgress.activity,
                                            });
                                        }}
                                    >
                                        Voir le détail
                                    </div>
                                    <div className={'ml-auto'}>
                                        <span
                                            className="he-paragraph--medium"
                                            style={{ color: getColor(score / totalGoodAnswers) }}
                                        >
                                            {score}
                                        </span>{' '}
                                        <span className="he-paragraph--small gray-400">/ {totalGoodAnswers}</span>
                                    </div>
                                </ActivityContainer>
                                {i < tab.length - 1 && <Line height={1} className="bg-gray-200" />}
                            </>
                        );
                    })
                    .filter(Boolean)}
                <Line height={1} className="bg-gray-300" />
                <div className="w-full flex justify-content-end align-items-center p-2">
                    <span className="he-paragraph--xs gray-500 mr-2">TOTAL</span>
                    <span
                        className="he-paragraph--regular"
                        style={{
                            fontSize: 18,
                            fontWeight: 'bold',
                        }}
                    >
                        {totalScore} <span className="he-paragraph--small font-bold">/ {total}</span>
                    </span>
                </div>
                <ScoreContainer className="bg-white mt-3">
                    <div className="flex flex-column align-items-center mb-4">
                        <span className="he-paragraph--small gray-500 mb-1">VOTRE SCORE :</span>
                        <div>
                            <DisplayScore style={{ color: getColor(totalScore / total) }} className="roboto">
                                {((100 * totalScore) / total).toFixed()} points
                            </DisplayScore>
                            <Percent className="roboto"> / 100</Percent>
                        </div>
                    </div>
                    <div className="w-full">
                        <TcsScale score={100 * (totalScore / total)} colors={colors} />
                    </div>
                </ScoreContainer>
            </>
        );
    };

    const responsive = useBreakpoint();

    return (
        <div>
            {selection && (
                <CustomDialog
                    visible
                    onHide={() => setSelection(null)}
                    closable
                    width={responsive.isDesktop ? 769 : responsive.isTablet ? 600 : '100%'}
                >
                    <div className="w-full bg-white flex align-items-center justify-content-end mb-4">
                        <i onClick={() => setSelection(null)} className="pi pi-times cursor-pointer" />
                    </div>
                    <ActivityPreview {...selection} />
                </CustomDialog>
            )}
            <Section className="mb-4 flex align-items-center">
                <div className="he-header--h2 gray-900">Vos résultats</div>
            </Section>
            {initialModule && initialModuleAnswers.length > 0 && (
                <Section className="mb-4">
                    <div className="he-header--h3 gray-600 mb-4 text-center">Questionnaire initial</div>
                    {generateActivityReport(initialModule)}
                </Section>
            )}
            {finalModule && finalModuleAnswers.length > 0 && (
                <Section className="mb-4">
                    <div className="he-header--h3 mb-4 gray-600 text-center">Questionnaire final</div>
                    {generateActivityReport(finalModule)}
                </Section>
            )}
            {finalModule &&
                initialModule &&
                initialModuleAnswers.length > 0 &&
                finalModuleAnswers.length > 0 &&
                finalTotalScore !== null &&
                initialTotalScore !== null &&
                finalTotalScore > initialTotalScore && (
                    <>
                        <Section className="mb-4">
                            <div className="he-header--h2 gray-900">Votre progression</div>
                        </Section>
                        <Section className="mb-4">
                            <div className="he-header--h2 text-center mb-3">Félicitations ! 👏</div>
                            <div className="he-paragraph--regular-16 text-center gray-700">
                                Suite au suivi de la formation, vous avez amélioré votre score final par rapport à votre
                                score initial
                            </div>
                        </Section>
                    </>
                )}
        </div>
    );
};
const Section = styled.div`
    background: #fff;
    border-radius: 10px;
    padding: 24px;
    max-width: 751px;
`;

const ActivityContainer = styled.div`
    padding: 16px 8px;
`;
const DisplayScore = styled.span`
    font-weight: 700;
    font-size: 20px;
    line-height: 130%;
`;

const Percent = styled.span`
    font-weight: 700;
    font-size: 10px;
    line-height: 130%;
    color: #667085;
`;
const ScoreContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border: 1px solid #eaecf0;
    border-radius: 8px;
`;
