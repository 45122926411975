import styled from 'styled-components';
import { ResponsiveProps } from '@Utils/responsive.utils';

export const AnswerWrapper = styled.div<{ is_image?: boolean } & ResponsiveProps>`
    display: grid;
    grid-template-rows: ${(props) => (props.is_image ? '270px' : 'minmax(46px, min-content)')};
    grid-auto-rows: ${(props) => (props.is_image ? '270px' : 'minmax(46pxn min-content)')};
    grid-template-columns: ${(props) => (props.is_image && props.responsive.isDesktop ? 'minmax(0, 1fr)' : '')} minmax(
            0,
            1fr
        );
    grid-gap: 8px;
    grid-auto-flow: ${(props) => (props.is_image ? 'column' : 'row')};
`;

export const AnswerWrapperNonResponsive = styled.div<{ is_image?: boolean }>`
    display: grid;
    grid-template-rows: ${(props) => (props.is_image ? '270px' : 'minmax(46px, min-content)')};
    grid-auto-rows: ${(props) => (props.is_image ? '270px' : 'minmax(46pxn min-content)')};
    grid-template-columns: minmax(0, 1fr);
    grid-gap: 8px;
    grid-auto-flow: ${(props) => (props.is_image ? 'column' : 'row')};
`;
