import { AuditModule, AuditModuleCategory } from '@Types/Module';
import { getTextColor } from '@Utils/color.utils';
import styled from 'styled-components';
import { ScoreProgress } from './components/ScoreProgress';
import { classNames } from 'primereact/utils';
import { useBreakpoint } from '@Utils/responsive.utils';

type Props = {
    cat: AuditModuleCategory;
    initialModuleScore: {
        score: {
            [theme: string]: number;
        };
        module: AuditModule;
    } | null;
    initialScore: number;
    score: number;
    comment: string;
    didImprove: boolean;
};
export const CategoryScore = (props: Props) => {
    const responsive = useBreakpoint();

    return (
        <div className="flex flex-column gap-3 align-items-start">
            <div
                className={classNames(
                    'flex gap-2 w-full',
                    !responsive.isDesktop && 'flex-column gap-4 align-items-start'
                )}
            >
                <div className="flex align-items-center gap-2 w-min">
                    <div className="he-paragraph--regular" style={{ minWidth: 120, maxWidth: 120 }}>
                        <ThemeTag background={props.cat.color}>{props.cat.label}</ThemeTag>
                    </div>
                    <div className="flex flex-column align-items-start" style={{ width: 120 }}>
                        <div className="flex align-items-center gap-1 ">
                            <small className="roboto he-paragrah--small gray-600">score </small>
                            <DisplayScore className="roboto gray-800">
                                {new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 2 }).format(props.score * 100)}
                            </DisplayScore>
                            <Percent className="roboto">%</Percent>
                        </div>
                    </div>
                </div>
                <ScoreProgress score={props.score} initialScore={props.initialScore} />
            </div>
            <TextAreaDisplay color={props.cat.color}>{props.comment ?? 'Aucun commentaire trouvé'}</TextAreaDisplay>
        </div>
    );
};

const ThemeTag = styled.div<{
    size?: number;
    background?: string;
    color?: string;
}>`
    text-align: center;
    padding: 6px 8px;
    border-radius: 6px;
    background: ${(props) => props.background || '#c8c8c8'};
    color: ${(props) => props.color || getTextColor(props.background || '#c8c8c8')};
    font-size: ${(props) => props.size || 14}px;
    line-height: ${(props) => (props.size ? props.size + 4 : 18)}px;
    font-family: 'roboto';
`;
const TextAreaDisplay = styled.div<{ color: string }>`
    line-height: 20px;
    font-size: 14px;
    font-family: 'roboto';
    font-weight: 400;
    white-space: pre-line;
    padding: 5px 10px 5px 10px;
    border-left: 4px solid ${(props) => props.color + '40'};
    background-color: ${(props) => props.color + '10'};
    width: 100%;
    margin-top: 14px;
`;

const DisplayScore = styled.span`
    font-weight: 700;
    font-size: 20px;
    line-height: 130%;
`;

const Percent = styled.span`
    font-weight: 700;
    font-size: 10px;
    line-height: 130%;
    color: #667085;
`;
