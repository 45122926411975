import { AuditRecord, User, UserProgressItem } from '@Types/User';
import { appFirebase, auth, firestore } from '@Utils/config/firebase';
import type Tracker from '@openreplay/tracker';
import { FirebaseApp } from 'firebase/app';
import { User as FirebaseUser } from 'firebase/auth';
import { collection, doc, getDocs, onSnapshot, query } from 'firebase/firestore';
import produce from 'immer';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { version } from '../config/version';
import { env } from '../env';
import { DateTime } from 'luxon';

export interface IFirebaseContext {
    user?: { firebase?: FirebaseUser; meta?: User; progress?: UserProgressItem[]; allRecords?: AuditRecord[] };
    refreshProgress: () => Promise<void>;
    logEvent: (name: string, str: string | object) => void;
}

export const FirebaseContext = React.createContext<IFirebaseContext | null>(null);

export const FirebaseProvider: React.FC<
    PropsWithChildren<{
        tracker: Tracker;
    }>
> = (props) => {
    const [app] = useState<FirebaseApp>(appFirebase);
    const [loading, setLoading] = useState<boolean>(true);
    const [user, setUser] = useState<IFirebaseContext['user']>(undefined);
    const [userAuth, setUserAuth] = useState<FirebaseUser | null | undefined>(undefined);

    const logEvent = <T extends string | object>(name: string, payload: T) => {
        const sentPayload: object = {
            ...(typeof payload === 'string' ? { data: payload } : payload),
            created_at: DateTime.now().setZone('Europe/Paris').toISO(),
        };
        console.debug('logEvent', name, sentPayload);
        props.tracker.event(name, sentPayload);
    };

    useEffect(() => {
        return auth.onAuthStateChanged((_userAuth) => {
            console.log('auth', _userAuth);
            if (_userAuth) {
                setUserAuth(_userAuth);
            } else {
                setUser({});
            }
            setLoading(false);
        });
    }, [app]);

    useEffect(() => {
        let authUnsub = () => {};
        let progressUnsub = () => {};
        let recordsUnsub = () => {};

        if (userAuth) {
            authUnsub = onSnapshot(doc(firestore.db, 'users', userAuth.uid), (user) => {
                const userData = user.data() as User;
                console.log(userAuth, userData);
                if (!env.isDev) {
                    props.tracker.setUserID(userData.email);
                    props.tracker.setMetadata('user_id', userData.user_id);
                    props.tracker.setMetadata('phone', userData.phone);
                    props.tracker.setMetadata('firstname', userData.firstname);
                    props.tracker.setMetadata('lastname', userData.lastname);
                    props.tracker.setMetadata('version', version);
                }
                setUser(
                    produce((draft) => {
                        if (draft) {
                            draft.firebase = userAuth;
                            draft.meta = userData;
                        } else {
                            draft = {
                                firebase: userAuth,
                                meta: userData,
                            };
                        }
                        return draft;
                    })
                );
            });

            recordsUnsub = onSnapshot(query(collection(firestore.db, 'users', userAuth.uid, 'records')), (snapshot) => {
                const sortedRecords: AuditRecord[] = [];
                snapshot.forEach((doc) => {
                    sortedRecords.push(doc.data() as AuditRecord);
                });

                sortedRecords.sort((a, b) => {
                    return a.created_at < b.created_at ? -1 : 1;
                });

                setUser(
                    produce((draft) => {
                        if (draft) draft.allRecords = sortedRecords;
                        else
                            draft = {
                                allRecords: sortedRecords,
                            };
                        return draft;
                    })
                );
            });

            progressUnsub = onSnapshot(collection(firestore.db, 'users', userAuth.uid, 'progress'), (snapshot) =>
                setUser(
                    produce((draft) => {
                        if (draft) draft.progress = snapshot.docs.map((doc) => doc.data() as UserProgressItem);
                        else
                            draft = {
                                progress: snapshot.docs.map((doc) => doc.data() as UserProgressItem),
                            };
                        return draft;
                    })
                )
            );
        }
        return () => {
            authUnsub();
            recordsUnsub();
            progressUnsub();
        };
    }, [userAuth]);

    const refreshProgress = async () => {
        if (!userAuth) return;
        setUser(
            produce((draft) => {
                if (draft) draft.progress = [];
                else
                    draft = {
                        progress: [],
                    };
                return draft;
            })
        );
        return getDocs(collection(firestore.db, 'users', userAuth.uid, 'progress')).then((snapshot) => {
            setUser(
                produce((draft) => {
                    if (draft) draft.progress = snapshot.docs.map((doc) => doc.data() as UserProgressItem);
                    else
                        draft = {
                            progress: snapshot.docs.map((doc) => doc.data() as UserProgressItem),
                        };
                    return draft;
                })
            );
            return;
        });
    };

    return (
        <FirebaseContext.Provider value={{ user, logEvent, refreshProgress }}>
            {loading ? (
                <div className="w-full h-full flex justify-content-center align-items-center bg-gray-200">
                    <i className="pi pi-spin pi-spinner gray-600" style={{ fontSize: '2rem' }} />
                </div>
            ) : (
                user !== undefined && props.children
            )}
        </FirebaseContext.Provider>
    );
};
