import { classNames } from 'primereact/utils';
import styled from 'styled-components';

type Props = {
    value: any[];
    marker: (item: any) => JSX.Element;
    className?: string;
};
export const CustomActivityStepper = (props: Props) => {
    return (
        <div className={classNames(props.className, 'py-4 flex flex-column justify-content-evenly align-items-center')}>
            {props.value.map((item, index) => {
                const isLast = index === props.value.length - 1;
                const isFirst = index === 0;
                return (
                    <div
                        key={index}
                        className={classNames('flex-1', 'flex flex-column align-items-center justify-content-center')}
                    >
                        {!isFirst ? <FullHeightDivider /> : <PlaceholderDivider />}
                        {props.marker(item)}
                        {!isLast ? <FullHeightDivider /> : <PlaceholderDivider />}
                    </div>
                );
            })}
        </div>
    );
};

const FullHeightDivider = styled.div`
    flex: 1;
    width: 2px;
    background-color: var(--gray-400);
`;

const PlaceholderDivider = styled.div`
    flex: 1;
    width: 2px;
    background-color: transparent;
`;
