import { CustomDialog } from '@Components/CustomDialog';
import { Line } from '@Components/Line';
import { ModuleContext } from '@Context/Module.context';
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';

const Summary = styled.div`
    grid-area: summary;
    height: 40px;
    background: #f1f7fd;
    .p-sidebar-header {
        display: none;
    }
`;

const SummaryDropdown = styled.div`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 130%;
    letter-spacing: 0.04em;
    height: 40px;
    text-transform: uppercase;
    i {
        font-size: 12px;
        line-height: 130%;
    }
`;

export type Props = {
    downloadFile: () => Promise<void>;
    recordCards: () => React.ReactElement[];
    loading: boolean;
    onQuit: () => void;
};
export const ResponsiveAuditSummary: React.FC<Props> = ({ downloadFile, recordCards, loading, onQuit }) => {
    const [visible, setVisible] = useState<boolean>(false);
    const [showConfirmDownload, setShowConfirmDownload] = useState<boolean>(false);
    const { module: currentModule } = useContext(ModuleContext);
    return (
        <Summary>
            <Sidebar
                onHide={() => setVisible(false)}
                visible={visible}
                position="top"
                className="w-full h-full bg-primary-7"
                showCloseIcon={false}
                appendTo="self"
            >
                <div className="w-full h-full flex flex-column">
                    {showConfirmDownload && (
                        <CustomDialog visible onHide={() => setShowConfirmDownload(false)} width={400}>
                            <div className="he-header--h2 mb-3 text-center">Téléchargement du support pédagogique</div>
                            <div className="he-paragraph--regular-16 gray-500 mb-4 text-center">
                                Ce support pédagogique a pour seul objectif de faciliter la compréhension et
                                l’apprentissage des vidéos suivantes, sa lecture ne sera pas prise en compte dans le
                                suivi de cette formation. <br />
                                <br /> Pour suivre la formation, cliquez sur "Formation"
                            </div>
                            <div className="flex flex-column gap-2 w-full">
                                <Button
                                    className="he-button--primary--md"
                                    onClick={downloadFile}
                                    loading={loading}
                                    disabled={loading}
                                >
                                    <i className="pi pi-download" />
                                    Télécharger le support
                                </Button>
                                <Button
                                    className="he-button--secondary-variant--md"
                                    onClick={() => setShowConfirmDownload(false)}
                                >
                                    Formation
                                </Button>
                            </div>
                        </CustomDialog>
                    )}
                    <SummaryDropdown
                        className="primary-100 flex justify-content-center align-items-center mt-2"
                        onClick={() => setVisible(false)}
                    >
                        Sommaire <i className="ml-2 pi pi-angle-up" />
                    </SummaryDropdown>
                    <Line height={1} style={{ background: '#d8ebfb' }} className="mb-3" />
                    <Button
                        className="w-full he-button--secondary-nf--xs--rounded"
                        onClick={() => setShowConfirmDownload(true)}
                        style={{ height: 40 }}
                        loading={loading}
                    >
                        <i className="pi pi-download" />
                        Télécharger le support pédagogique
                    </Button>
                    <Button className="mt-3 he-button--secondary-variant-nf--xs" onClick={onQuit}>
                        <i className="pi pi-times mr-2" />
                        Quitter le module
                    </Button>
                    <div className="flex flex-column flex-1 overflow-auto">
                        <div className="pb-3 min-h-0">
                            {currentModule && recordCards()}
                            <div className="w-full flex flex-column justify-content-between align-items-center mt-4">
                                <a
                                    className="w-max text-center he-paragraph--regular--bold cursor-pointer no-underline mb-3"
                                    style={{ color: 'rgba(11,85,153,0.58)' }}
                                    href={'mailto:contact@healthevents.fr'}
                                >
                                    <i className="pi pi-envelope mr-2" />
                                    Contacter l'animateur
                                </a>
                                <a
                                    className="w-max text-center he-paragraph--regular--bold cursor-pointer no-underline"
                                    style={{ color: 'rgba(11,85,153,0.58)' }}
                                    href={'tel:0176431249'}
                                >
                                    <i className="pi pi-phone mr-2" />
                                    01 76 43 12 49
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </Sidebar>
            <SummaryDropdown
                className="primary-100 w-full h-full flex justify-content-center align-items-center"
                onClick={() => setVisible(true)}
            >
                Sommaire <i className="ml-2 pi pi-angle-down" />
            </SummaryDropdown>
        </Summary>
    );
};
