export const firebaseConfigProd = {
    apiKey: 'AIzaSyCE_32diccdUjXsmnkcXSJXZI2IqkNHERk',
    authDomain: 'health-events-dev.firebaseapp.com',
    projectId: 'health-events-dev',
    storageBucket: 'health-events-dev.appspot.com',
    messagingSenderId: '204933489081',
    appId: '1:204933489081:web:9e7ae6088e6ea14d8ad668',
};

export const firebaseConfigStaging = {
    apiKey: 'AIzaSyB764OIYqa9Xf1IsFasM5WRgc68L0klM1A',
    authDomain: 'health-events-staging.firebaseapp.com',
    projectId: 'health-events-staging',
    storageBucket: 'health-events-staging.firebasestorage.app',
    messagingSenderId: '158089462704',
    appId: '1:158089462704:web:2783bdfdd8b0dd486ecc75',
};

const remoteEnv = process.env.REACT_APP_ENV ?? 'prod';

const firebaseEnv = remoteEnv === 'prod' ? firebaseConfigProd : firebaseConfigStaging;

const useFunctionEmulator = false;

const isProd = true;

const hostsMap: Record<string, string> = {
    prod: 'https://lms.healthevents.fr',
    staging: 'https://health-events-staging.web.app',
};

const host = isProd ? hostsMap[remoteEnv] : 'localhost:3000';

export const env = {
    firebaseEnv,
    host,
    isFirebaseProductionProjet: isProd,
    useFunctionEmulator,
    debugInfo: false,
    maintenanceKey: 'maintenance',
    isDev: !isProd,
    functionRegion: 'europe-west3',
};
