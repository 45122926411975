import { SessionContext } from '@Context/Session.context';
import { useApi } from '@Hooks/api';
import { useUser } from '@Hooks/firebase';
import { usePromise } from '@Hooks/promise';
import { Module } from '@Types/Module';
import { SessionParticipant } from '@Types/Session';
import { Unit } from '@Types/Unit';
import { AuditRecord } from '@Types/User';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FirebaseContext } from './FirebaseContext';

export interface ModuleContextI {
    module?: Module;
    modules: Module[];
    records: AuditRecord[];
    unit?: Unit;
    participant?: SessionParticipant;
}

export const ModuleContext = React.createContext<ModuleContextI>({
    modules: [],
    records: [],
    module: undefined,
    participant: undefined,
    unit: undefined,
});

export const ModuleProvider: React.FC<React.PropsWithChildren> = (props) => {
    const [participant, setParticipant] = useState<SessionParticipant | undefined>();
    const [unitModules, setUnitModules] = useState<Module[]>([]);

    const params = useParams<{ session_id: string; module_id: string }>();
    const { session, modules } = useContext(SessionContext);
    const fbCtx = useContext(FirebaseContext);
    const user = useUser(true);
    const api = useApi();

    const module = useMemo(() => {
        fbCtx?.logEvent('changeModule', { modules, module_id: params.module_id });
        return modules.find((m) => m.module_id === params.module_id);
    }, [modules, params.module_id]);

    const records = useMemo(() => {
        if (!module || !user.allRecords) return [];
        return user.allRecords.filter((r) => r.module_id === module.module_id && r.session_id === params.session_id);
    }, [module, user.allRecords]);

    const [loadParticipants] = usePromise(async (session_id: string) => {
        const sessionRes = await api.session_call_get({
            session_id,
        });
        if (sessionRes.result === 'ok') {
            setParticipant(sessionRes.participants.find((p) => p.participant_id === user.firebase.uid));
        }
    });

    const unit = useMemo(() => {
        if (session && 'formation' in session && module) {
            const currentUnit = session.formation.units.find((m) => m.modules_ids.includes(module.module_id));
            if (currentUnit) {
                setUnitModules(modules.filter((m) => currentUnit.modules_ids.includes(m.module_id)));
                return currentUnit;
            }
        }
        return undefined;
    }, [session, module, modules]);

    useEffect(() => {
        if (params.session_id) loadParticipants(params.session_id);
    }, [params.module_id, params.session_id, session]);

    return (
        <ModuleContext.Provider
            value={{
                module,
                modules: unitModules,
                unit,
                records,
                participant,
            }}
        >
            {props.children}
        </ModuleContext.Provider>
    );
};
