import { BreadItemModel } from '@Components/CustomBreadCrumb';
import { Line } from '@Components/Line';
import { ReadOnlyEditor } from '@Components/ReadOnlyEditor';
import { ModuleContext } from '@Context/Module.context';
import { SessionContext } from '@Context/Session.context';
import { useApi } from '@Hooks/api';
import { useBread } from '@Hooks/breadcrumbs';
import { useUser } from '@Hooks/firebase';
import { useToast } from '@Hooks/toast';
import { ModuleSkeleton } from '@Pages/Home/Session/pages/Module/components/ModuleSkeleton';
import { ModuleTopBar } from '@Pages/Home/Session/pages/Module/components/ModuleTopBar';
import { iconForActivity } from '@Utils/activity.utils';
import { isSessionFinished } from '@Utils/progress.utils';
import { getUrlForResource } from '@Utils/resource.utils';
import { ResponsiveProps, useBreakpoint } from '@Utils/responsive.utils';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { AuditRecordCard } from './components/AuditRecordCard';

const ContentWrapper = styled.div<ResponsiveProps>`
    position: relative;
    max-width: 1076px;
    padding: ${(props) => (props.responsive.isDesktop || props.responsive.isTablet ? '0 32px' : '0 16px')};
`;

const InnerWrapper = styled.div`
    max-width: 710px;
`;

const CoverImageWrapper = styled.div<ResponsiveProps>`
    height: ${(props) => (props.responsive.isDesktop || props.responsive.isTablet ? '200px' : '150px')};
    overflow: hidden;
`;

const SummaryCard = styled.div`
    border-left: 4px solid #3c90e3;
    border-radius: 0px 10px 10px 0px;
`;

const AuditContainer = styled.div`
    display: flex;
`;

const AuditGridContainer = styled.div<ResponsiveProps>`
    display: grid;

    ${(props) =>
        props.responsive.isDesktop
            ? `
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: repeat(3, 180px);
        grid-auto-rows: 180px;
        gap: 8px;
    `
            : props.responsive.isTablet
            ? `
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: repeat(4, 180px);
        grid-auto-rows: 180px;
        gap: 8px;
    `
            : `
        grid-template-columns: 1fr;
        grid-template-rows: repeat(8, 180px);
        grid-auto-rows: 180px;
        gap: 8px;
    `}
`;

const AuditGridItem = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    height: 100%;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
        transform: scale(1.01);
    }

    .user-icon {
        font-size: 36px;
        color: #c2c2c2;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;

export type ModuleProps = Record<string, never>;
export const Module: React.FC<ModuleProps> = () => {
    const params = useParams<{
        session_id: string;
        module_id: string;
    }>();

    const navigate = useNavigate();
    const bread = useBread();

    const { module, modules, participant, records } = useContext(ModuleContext);
    const { session, refreshModules: loadModule, loadingModules: loading } = useContext(SessionContext);
    const { progress: userProgress, allRecords } = useUser(true);
    const [image, setImage] = useState<string>();

    useEffect(() => {
        if (session?.formation?.cover_image_big_url || module?.cover_image_url || session?.formation?.cover_image_url) {
            getUrlForResource(
                session?.formation?.cover_image_big_url ||
                    session?.formation?.cover_image_url ||
                    module?.cover_image_url
            ).then((res) => {
                setImage(res?.resource_url);
            });
        }
    }, [module?.cover_image_url, session?.formation?.cover_image_big_url, session?.formation?.cover_image_url]);

    // variables

    const finishedPreviousModules = useMemo<boolean>(() => {
        const moduleIndex = modules.findIndex((m) => m.module_id === params.module_id);

        if (moduleIndex === 0) return true;

        if (!params.session_id || !userProgress || !module) return false;

        const sessionProgress = userProgress.filter((p) => p.session_id === params.session_id);

        if (!session) return false;

        return modules
            .filter((m, i) => i < moduleIndex)
            .every((m) => {
                const activitiesDone = sessionProgress.filter((sp) => sp.activity.done && sp.module_id === m.module_id);
                return activitiesDone.length === m.activities.length;
            });
    }, [modules, params.session_id, params.module_id, userProgress, module, session]);

    const progress = useMemo(() => {
        if (!module || !params.session_id) return 0;

        if (module.type !== 'audit') {
            const moduleProgress = userProgress?.filter(
                (p) => p.session_id === params.session_id && p.module_id === module.module_id && p.activity.done
            );

            if (!moduleProgress) return 0;

            return (100 * moduleProgress.length) / module.activities.length;
        } else {
            if (!params.session_id) return 0;

            const totalMaxRecords = Math.min(Math.max(module.min_records, records.length), module.max_records);
            const totalActivities = module.activities.length * totalMaxRecords;

            const completedActivities = records.reduce((acc, record) => {
                return acc + record.answers.length;
            }, 0);

            return (100 * completedActivities) / totalActivities;
        }
    }, [module, userProgress, params]);

    // Lifecycle
    useEffect(() => {
        loadModule(session).then((res) => {
            if (!res) return;
            bread.updateTree((prev: BreadItemModel[]) => {
                const module = res.find((m) => m.module_id === params.module_id);

                if (!module) return prev;

                if (prev[prev.length - 1].id === params.module_id) return prev;

                return [
                    {
                        url: `/session/${params.session_id}`,
                        label: `Session`,
                        id: 'session',
                    },
                    {
                        url: `/session/${params.session_id}/module/${params.module_id}`,
                        label: module.title,
                        id: params.module_id,
                    },
                ];
            });
        });
    }, [params.module_id, params.session_id, session]);

    // functions
    function onBack() {
        navigate(`/session/${params.session_id}`);
    }

    function onStart() {
        if (module && module.activities.length > 0)
            navigate(
                `/session/${params.session_id}/module/${params.module_id}/activity/${module.activities[0].activity_id}`
            );
    }

    const responsive = useBreakpoint();

    const api = useApi();
    const { error, success } = useToast();
    const [loadingNewRecord, setLoadingNewRecord] = React.useState(false);

    const onNewRecord = async () => {
        if (!params.session_id || !module) return;

        setLoadingNewRecord(true);
        try {
            const res = await api.session_call_addRecord({
                module_id: module.module_id,
                session_id: params.session_id,
            });

            if (res.result === 'ok') {
                success('Dossier patient créé');
            } else {
                throw new Error(res.result);
            }
        } catch (e) {
            console.error(e);
            error('Erreur lors de la création du dossier patient');
        } finally {
            setLoadingNewRecord(false);
        }
    };

    const recordsCards = useMemo(() => {
        if (!module || module.type !== 'audit' || !allRecords || !session) return <></>;

        let recordElems: React.ReactElement[] = records.map((r) => {
            return <AuditRecordCard module={module} record={r} key={r.record_id} index={records.indexOf(r)} />;
        });

        const finalModule = modules.find((m) => m.type === 'audit' && m.audit_type === 'final');
        const initialModule = modules.find((m) => m.type === 'audit' && m.audit_type === 'initial');

        let min = module.min_records;
        let max = module.max_records;

        if (finalModule && finalModule.module_id === module.module_id && initialModule) {
            max = allRecords.filter(
                (r) => r.module_id === initialModule?.module_id && r.session_id === session.session_id
            ).length;
            min = max;
        }

        for (let i = records.length; i < min; i++) {
            recordElems.push(<AuditRecordCard module={module} key={i} index={i} />);
        }

        return [
            ...recordElems,
            records.length >= min && records.length < max && records.some((r) => !r.submitted) && (
                <AuditGridItem className="py-4" onClick={onNewRecord}>
                    <i
                        className={classNames(
                            'absolute pi',
                            loadingNewRecord ? 'pi-spin pi-spinner' : 'pi-plus-circle',
                            'user-icon'
                        )}
                    />
                    <div className="he-paragraph--regular-16 gray-400 text-center">
                        Dossier patient <br /> supplémentaire
                    </div>
                </AuditGridItem>
            ),
        ];
    }, [module, records, loadingNewRecord, modules, allRecords]);

    return !loading && module ? (
        <div className="w-full h-full">
            <CoverImageWrapper
                responsive={responsive}
                className="bg-gray-400 w-full"
                style={{ backgroundImage: `url(${image})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
            ></CoverImageWrapper>
            <ModuleTopBar module={module} progress={progress} />
            <ContentWrapper responsive={responsive} className="m-auto mt-5 pb-5">
                <div className="he-paragraph--regular gray-500">
                    <span className=" cursor-pointer" onClick={onBack}>
                        <i className="pi pi-arrow-left mr-2" />
                        Retour à la liste des modules
                    </span>
                </div>

                <InnerWrapper className="mt-5">
                    <div className="he-header--h1 gray-900">{module.title}</div>
                    {module.description && (
                        <div className="quill-custom mt-5">
                            <ReadOnlyEditor content={module.description || null} />
                        </div>
                    )}

                    {module.type !== 'audit' ? (
                        <SummaryCard className="px-5 py-3 bg-white w-full mt-5">
                            <div className="he-header--h2 gray-900">Sommaire du module</div>
                            {module.description && <div className="he-paragraph--regular gray-500 mt-3">Préambule</div>}
                            {module.activities.map((activity, i) => {
                                return (
                                    <div className="he-paragraph--regular gray-500 mt-3 ml-3" key={i}>
                                        <i className={classNames(iconForActivity(activity.type), 'mr-2')} />
                                        {activity.title}
                                    </div>
                                );
                            })}
                        </SummaryCard>
                    ) : (
                        <AuditContainer className="px-5 py-3 bg-white w-full mt-5 flex flex-column">
                            <div className="flex justify-content-between align-items-center">
                                <div className="he-header--h2 gray-800">Vos dossiers patients</div>
                                <div>
                                    {records.length > 0 && records.every((r) => r.submitted) && (
                                        <Button
                                            label="Voir les résultats"
                                            className="he-button--secondary--xs"
                                            icon="pi pi-chart-bar"
                                            iconPos="right"
                                            onClick={() =>
                                                navigate(
                                                    '/session/' +
                                                        params.session_id +
                                                        '/module/' +
                                                        params.module_id +
                                                        '/results'
                                                )
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                            <Line height={1} className="bg-gray-300 my-3" />
                            <AuditGridContainer responsive={responsive} className="w-full">
                                {recordsCards}
                            </AuditGridContainer>
                        </AuditContainer>
                    )}
                </InnerWrapper>
                {module.type !== 'audit' && (
                    <Button
                        disabled={!finishedPreviousModules || (participant && isSessionFinished(participant))}
                        label="Lancer le module"
                        icon="pi pi-play"
                        className="he-button--primary--md mt-5"
                        onClick={onStart}
                    />
                )}
            </ContentWrapper>
        </div>
    ) : (
        <ModuleSkeleton />
    );
};
