import { AnswerItem } from '@Pages/Home/Session/pages/Module/pages/Activity/QuizzActivity/components/AnswerItem';
import { AnswerWrapper } from '@Pages/Home/Session/pages/Module/pages/Activity/QuizzActivity/components/AnswerWrapper';
import { ImageAnswer } from '@Pages/Home/Session/pages/Module/pages/Activity/QuizzActivity/components/ImageAnswer';
import { Answer } from '@Types/Quizz';
import { useBreakpoint } from '@Utils/responsive.utils';
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import { CustomRadioButton } from './components/CustomRadioButton';
import { Tag } from 'primereact/tag';
import React, { useEffect } from 'react';
import { InputTextarea } from 'primereact/inputtextarea';
import styled from 'styled-components';
import { useDebounce } from 'react-use';

export type UCQProps = {
    answers: Answer[];
    is_image?: boolean;
    onSelect?: (selection: number[]) => void;
    selection?: number[];
    onUpdateOther?: (text: string, index: number) => void;
    showDetailAnswer?: number;
    detailAnswer?: string;
    onUpdateAnswerDetail?: (text: string) => void;
    showTextHelp?: boolean;
    others: { [key: number | string]: string };
    is_done?: boolean;
};

export const UCQ: React.FC<UCQProps> = (props) => {

    const [loading, setLoading] = React.useState(false);

    const onSelectItem = (index: number) => {
        if (!props.onSelect || props.is_done) return;

        if (props.selection?.includes(index)) props.onSelect(props.selection.filter((i) => i !== index));
        else props.onSelect([index]);
    };

    const responsive = useBreakpoint();

    const [tmpDetail, setTmpDetail] = React.useState(props.detailAnswer);

    useEffect(() => {
        setLoading(true);
    }, [tmpDetail])

    useDebounce(() => {
        if (props.onUpdateAnswerDetail && tmpDetail)
            props.onUpdateAnswerDetail(tmpDetail);
        setLoading(false);
    }, 2000, [tmpDetail]);


    return (
        <AnswerWrapper responsive={responsive} is_image={props.is_image}>
            {props.answers.map((answer, i) => {
                return (
                    <AnswerItem
                        is_selected={props.selection?.includes(i)}
                        is_correct={props.is_done ? answer.is_response : undefined}
                        className="flex w-full h-full align-items-center cursor-pointer gap-2"
                        key={i}
                        onClick={() => !answer.other && onSelectItem(i)}
                    >
                        {props.is_done && props.showTextHelp !== false &&
                            (answer.is_response ? (
                                <Tag severity="success" style={{ width: 42 }} className="uppercase">
                                    Vrai
                                </Tag>
                            ) : (
                                <Tag severity="danger" style={{ width: 42 }} className="uppercase">
                                    Faux
                                </Tag>
                            ))}
                        {props.is_image ? (
                            <ImageAnswer
                                answer={answer}
                                index={i}
                                element={<RadioButton className="mr-2" checked={props.selection?.includes(i)} />}
                            />
                        ) : (
                            <>
                                <CustomRadioButton
                                    checked={props.selection?.includes(i)}
                                    onChange={() => answer.other && onSelectItem(i)}
                                />
                                <div className="flex flex-column gap-2 w-full">
                                    <span className="he-paragraph--regular gray-900">{answer.text_or_image}</span>
                                    {answer.other && (
                                        <InputText
                                            disabled={props.is_done}
                                            value={props.others[i]}
                                            onChange={(e) => props.onUpdateOther?.(e.target.value, i)}
                                            placeholder={'Saisir votre réponse ici'}
                                            className={'w-full'}
                                        />
                                    )}
                                </div>
                            </>
                        )}
                    </AnswerItem>
                );
            })}
            {props.showDetailAnswer &&
                props.onUpdateAnswerDetail &&
                props.selection?.[0] === props.showDetailAnswer && (
                    <div className="flex flex-column gap-1 mt-2">
                        <label className="he-paragraph--small--bold gray-800">{props.is_done ? "Détail de" : "Détaillez"} votre réponse ci-dessous {loading && <i className="pi pi-spin pi-spinner"/>}</label>
                        {!props.is_done ? (
                            <InputTextarea
                                value={tmpDetail}
                                onChange={(e) => setTmpDetail(e.target.value)}
                                className="w-full"
                                rows={5}
                                style={{ minHeight: 80 }}
                                placeholder="Saisir le détail de votre réponse ici"
                            />
                        ) : (
                            <MultilineDiv className="he-paragraph--small gray-800">{props.detailAnswer}</MultilineDiv>
                        )}
                    </div>
                )}
        </AnswerWrapper>
    );
};


const MultilineDiv = styled.div`
    white-space: pre-line;
    word-break: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
`