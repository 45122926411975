import { Module } from '@Types/Module';
import { Session, SessionExtended, SessionParticipant, SessionTimeReport } from '@Types/Session';
import { DurationUnit } from '@Types/Time';
import { AuditRecord, UserProgress, UserProgressItem } from '@Types/User';
import _ from 'lodash';
import { DateTime } from 'luxon';

export const isSessionStarted = (session: Session, progress?: UserProgressItem[], records?: AuditRecord[]) => {
    const sessionProgress = progress?.find((sp) => sp.session_id === session.session_id);
    const sessionRecord = records?.find((r) => r.session_id === session.session_id);
    return !sessionProgress && !sessionRecord;
};

export const isSessionFinished = (participant?: SessionParticipant | null) => {
    const customSessionEndDate = participant
        ? DateTime.fromISO(participant.end_date)
              .setLocale('Europe/Paris')
              .endOf('day')
              .diffNow('seconds')
              .get('seconds')
        : undefined;
    return customSessionEndDate ? customSessionEndDate <= 0 : true;
};

export const getRemainingModuleTime = (module_id: string, currentModule: Module, report: SessionTimeReport) => {
    const module = report.modules.find((m) => m.module_id === module_id);
    if (module) {
        if (currentModule?.minimal_duration == null || currentModule?.minimal_duration_unit == null) return null;
        const durationToMillis = (qty: number, unit: DurationUnit) => {
            switch (unit) {
                case 'h':
                    return qty * 60 * 60 * 1000;
                case 's':
                    return qty * 7 * 24 * 60 * 60 * 1000;
                case 'm':
                    return qty * 60 * 1000;
                case 'j':
                    return qty * 24 * 60 * 60 * 1000;
                default:
                    return 0;
            }
        };
        const minimumTimeInSeconds = durationToMillis(
            currentModule.minimal_duration,
            currentModule.minimal_duration_unit
        );

        const res = minimumTimeInSeconds - module.total;

        return res;
    } else return null;
};


export const getLastActivityLegacy = (
    progressItems: UserProgressItem[],
    session: SessionExtended<false>,
    modules: Module[],
    participant: SessionParticipant,
    sessionReport: SessionTimeReport,
    onError: (error: string, redirectUrl?: string) => void
) => {

    const progress: UserProgress = _.mapValues(_.groupBy(progressItems, 'session_id'), (items) => {
        return _.mapValues(_.groupBy(items, 'module_id'), (items) => {
            return _.mapValues(_.groupBy(items, 'activity_id'), (items) => {
                return items[0].activity;
            });
        });
    });

    const sessionProgressAll = progress[session.session_id];

    if (!sessionProgressAll) return undefined;

    const firstModule = session.formation.modules[0];
    const firstModuleComplete = modules.find(m => m.module_id === firstModule.module_id);

    if (!firstModuleComplete) return undefined;
    const firstModuleActivity = firstModuleComplete.activities[0];

    if (
        firstModuleActivity?.activity_id &&
        sessionProgressAll[firstModule.module_id]?.[firstModuleActivity.activity_id]?.done === false
    ) {
        return {
            module_id: firstModule.module_id,
            activity_id: firstModuleActivity.activity_id,
        };
    }

    // Remove modules that have no done activities from sessionProgress
    const sessionModuleDone = Object.fromEntries(
        Object.entries(sessionProgressAll).filter(([moduleId, module]) => {
            if (!module) return false;
            return Object.values(module).some((a) => a?.done);
        })
    );

    // Find the last module ID that has done activities
    let lastModuleId = _.last(
        _.intersection(
            session.formation.modules.map((e) => e.module_id),
            Object.keys(sessionModuleDone)
        )
    );

    // If no last module ID is found, return undefined
    if (!lastModuleId) return undefined;

    // Get the last module with done activities
    const lastModule = sessionModuleDone[lastModuleId];
    let lastModuleOriginalIndex = modules.findIndex((e) => e.module_id === lastModuleId);
    // Get module meta
    let lastModuleOriginal = modules[lastModuleOriginalIndex];

    // Find the unit that contains the last module
    let lastModuleUnit = session.formation.units.find((u) => u.modules_ids.includes(lastModuleOriginal.module_id));
    let lastModuleUnitIndex = session.formation.units.findIndex((u) =>
        u.modules_ids.includes(lastModuleOriginal.module_id)
    );

    // Check if all previous units are signed
    const signedPreviousUnits = Boolean(
        participant.unitsMeta?.every((u, i) => {
            const unitSessionMeta = session.unitsConfig.find((us) => us.unit_id === u.unit_id);
            return i < lastModuleUnitIndex
                ? unitSessionMeta?.required_signature
                    ? Boolean(u.signature_id)
                    : true
                : true;
        })
    );

    // If not all previous units are signed, show an error and return undefined
    if (!signedPreviousUnits) {
        const index = participant.unitsMeta?.findIndex((u) => !u.signature_id);
        if (index !== undefined && index !== -1) {
            onError(
                `Vous devez signer l'attestation sur l'honneur de la partie ${
                    index + 1
                } avant de continuer la formation`,
                `/session/${session.session_id}?u=${index}`
            );
            return undefined;
        }
    }

    // If no last module or last module original is found, return undefined
    if (!lastModule || !lastModuleOriginal) return undefined;

    // Get the activities of the last module
    let moduleActivities = lastModuleOriginal.activities;
    const lastModuleActivities = Object.values(lastModule).filter((a) => a?.done);

    // Find the index of the last done activity
    let lastDoneActivityIndex = moduleActivities
        .map((a) => lastModuleActivities.findIndex((la) => la?.activity_id === a.activity_id))
        .reduce((a, c) => Math.max(a, c), 0);

    // If the last done activity is the last activity in the module, change module
    if (lastDoneActivityIndex + 1 >= moduleActivities.length) {
        console.log('changing module');
        const oldModule = lastModuleOriginal;

        // Move to the next module
        lastModuleOriginalIndex++;
        lastModuleOriginal = modules[lastModuleOriginalIndex];

        // If the next module exists
        if (lastModuleOriginal) {
            const newUnit = session.formation.units.find((u) =>
                u.modules_ids.includes(lastModuleOriginal.module_id)
            );

            // If changing unit
            if (lastModuleUnit && newUnit && lastModuleUnit.unit_id !== newUnit.unit_id) {
                const newUnitConfig = session.unitsConfig.find((u) => u.unit_id === newUnit.unit_id);
                const lastUnitConfig = session.unitsConfig.find((u) => u.unit_id === lastModuleUnit!.unit_id);
                const lastUnitMeta = participant.unitsMeta?.find((u) => u.unit_id === lastModuleUnit!.unit_id);
                const newModuleUnitIndex = session.formation.units.findIndex((u) => u.unit_id === newUnit.unit_id);

                // If no last unit meta is found, return undefined
                if (!lastUnitMeta) return undefined;

                // If the previous unit requires a signature and it is not signed, show an error and return undefined
                if (!lastUnitMeta.signature_id && lastUnitConfig?.required_signature === true) {
                    onError(
                        "Vous devez signer l'attestation sur l'honneur de la partie précédente avant de continuer la formation",
                        `/session/${session.session_id}?u=${newModuleUnitIndex - 1}`
                    );
                    return undefined;
                }

                // If no new unit config is found, return undefined
                if (!newUnitConfig) return undefined;

                // If the next unit has a start date and it is not yet open, show an error and return undefined
                if (
                    newUnitConfig.start_date &&
                    DateTime.now()
                        .setZone('Europe/Paris')
                        .diff(
                            DateTime.fromISO(newUnitConfig.start_date).setZone('Europe/Paris').startOf('day'),
                            'seconds'
                        )
                        .get('seconds') < 0
                ) {
                    onError(
                        'Vous pourrez continuer la formation une fois la partie suivant ouverte.',
                        `/session/${session.session_id}`
                    );
                    return undefined;
                }

                // Get the remaining time for the old module
                const lastModuleTime = getRemainingModuleTime(oldModule.module_id, oldModule, sessionReport);

                // If remaining time is found
                if (lastModuleTime !== null) {
                    // If remaining time is greater than 0, return the old module and last activity
                    if (lastModuleTime > 0) {
                        return {
                            module_id: oldModule.module_id,
                            activity_id: _.last(oldModule.activities.map((a) => a.activity_id)),
                        };
                    }
                    // If remaining time is 0, return the new module and first activity
                    else {
                        return {
                            module_id: newUnit.modules_ids[0],
                            activity_id: modules.find((m) => m.module_id === newUnit.modules_ids[0])?.activities[0]
                                ?.activity_id,
                        };
                    }
                }
            }

            // Get the remaining time for the old module
            const lastModuleTime = getRemainingModuleTime(oldModule.module_id, oldModule, sessionReport);
            // If remaining time is greater than 0, return the old module and last activity
            if (lastModuleTime !== null && lastModuleTime > 0) {
                return {
                    module_id: oldModule.module_id,
                    activity_id: _.last(oldModule.activities.map((a) => a.activity_id)),
                };
            }

            // If no next module is found, return undefined
            if (!lastModuleOriginal) return undefined;
            lastModuleId = lastModuleOriginal.module_id;

            // Update module activities and reset last done activity index
            moduleActivities = lastModuleOriginal.activities;
            lastDoneActivityIndex = -1;
        } else {
            // If no next module exists, return the last module and last activity with finished flag
            return {
                module_id: lastModuleId,
                activity_id: moduleActivities[lastDoneActivityIndex].activity_id,
                finished: true,
            };
        }
    }

    // Get the next activity in the module
    const lastActivity = moduleActivities[lastDoneActivityIndex + 1];
    // If no next activity is found, return undefined
    if (!lastActivity) return undefined;

    // Return the last module and next activity
    return { module_id: lastModuleId, activity_id: lastActivity.activity_id };

}

const getLastActivityOfModule = (
    session: SessionExtended<false>,
    module: Module,
    progressItems: UserProgressItem[],
    records: AuditRecord[],
) => {
    const progress: UserProgress = _.mapValues(_.groupBy(progressItems, 'session_id'), (modules) => {
        return _.mapValues(_.groupBy(modules, 'module_id'), (activities) => {
            return _.mapValues(_.groupBy(activities, 'activity_id'), (items) => {
                return items[0].activity;
            });
        });
    });

    const moduleProgress = progress[session.session_id]?.[module.module_id];
    const moduleRecords = records.filter(r => r.module_id === module.module_id && r.session_id === session.session_id);

    if (!moduleProgress && moduleRecords.length === 0) return undefined;

    if (module.type === "audit") {

        if (moduleRecords.length === 0) return undefined;

        const lastModuleRecord = _.sortBy(moduleRecords, r => r.created_at).reverse()[0];

        if (!lastModuleRecord) return undefined;

        return {
            module_id: lastModuleRecord.module_id,
            record_id: lastModuleRecord.record_id,
            session_id: lastModuleRecord.session_id,
        }

    } else {
        if (!moduleProgress) return undefined;

        const hasDoneActivities = Object.values(moduleProgress).some(a => a?.done);

        if (!hasDoneActivities)
            return undefined;
        
        const lastActivity = module.activities.findLastIndex(a => a.activity_id && moduleProgress[a.activity_id]?.done);

        if (lastActivity === -1) return undefined;


        return {
            module_id: module.module_id,
            activity_id: module.activities[lastActivity].activity_id,
        }
    }

}


export const getLastActivity = (
    records: AuditRecord[],
    progressItems: UserProgressItem[],
    session: SessionExtended<false>,
    modules: Module[],
    participant: SessionParticipant,
    sessionReport: SessionTimeReport,
    timeSinceDownload: number,
    onError: (error: string, redirectUrl?: string) => void
) => {
    
    const sessionModules = session.formation.modules.map(m => modules.find(mod => mod.module_id === m.module_id)).filter(m => m!==undefined);

    if (sessionModules.every(m => m.type !== "audit")) return getLastActivityLegacy(progressItems, session, modules, participant, sessionReport, onError);

    const lastActivityOfModules = sessionModules
        .map(m => getLastActivityOfModule(session, m, progressItems, records))
        .filter(Boolean);
    
    const moduleOrdered = session.formation.units
        .flatMap(u => u.modules_ids)
        .map(mid => modules.find(m => mid === m.module_id))
        .filter(m => m && lastActivityOfModules.some(l => l?.module_id === m.module_id))
        .map(m => ({
            module: m!,
            lastActivity: lastActivityOfModules.find(l => l?.module_id === m!.module_id),
        }));

    const lastModuleWithActivity = moduleOrdered[lastActivityOfModules.length - 1];

    if (!lastModuleWithActivity) return undefined;

    if (lastModuleWithActivity.module.type === "audit") {
        return {
            module_id: lastModuleWithActivity.module.module_id,
            activity_id: undefined,
            finished: undefined
        }
    } else {
        return {
            module_id: lastModuleWithActivity.module.module_id,
            activity_id: lastModuleWithActivity.lastActivity?.activity_id,
            finished: undefined
        }
    }

};
