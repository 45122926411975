import { Activity, QuizzActivity } from '@Types/Activity';
import { forwardRef, useEffect, useMemo, useState } from 'react';
import { UCQ } from '../../Activity/QuizzActivity/UCQ';
import { Resource } from '@Types/Resource';
import { classNames } from 'primereact/utils';
import { useBreakpoint } from '@Utils/responsive.utils';
import { ReadOnlyEditor } from '@Components/ReadOnlyEditor';
import { Galleria } from 'primereact/galleria';
import { Image } from 'primereact/image';
import ReactPlayer from 'react-player';
import { getUrlForResource } from '@Utils/resource.utils';
import { AuditRecord } from '@Types/User';
import styled from 'styled-components';

type Props = {
    record: AuditRecord;
    activity: Activity & QuizzActivity;
    index: number;
    onClick: (activity: Activity & QuizzActivity, selection: number[]) => void;
    onUpdateAnswerDetail?: (activity: Activity & QuizzActivity, detail: string) => void;
};
export const RecordActivityPreview = forwardRef<HTMLDivElement, Props>((props, ref) => {
    const [images, setImages] = useState<(null | Resource | undefined)[]>([]);
    const [videos, setVideos] = useState<Array<null | Resource | undefined>>([]);

    const responsive = useBreakpoint();

    const responsiveGalleriaOptions = [
        {
            breakpoint: '768px',
            numVisible: 2,
        },
        {
            breakpoint: '450px',
            numVisible: 1,
        },
    ];

    useEffect(() => {
        Promise.all(
            props.activity.cover_image_url.map(async (i?: string | null) => {
                if (i) {
                    return getUrlForResource(i);
                }
                return null;
            })
        ).then((imgs) => {
            setImages(imgs.filter((e) => !!e));
        });
        Promise.all(
            (props.activity.cover_video_url ?? []).map(async (i?: string | null) => {
                if (i) {
                    return getUrlForResource(i);
                }
                return null;
            })
        ).then((vids) => {
            console.log('new request');
            setVideos(vids.filter(Boolean));
        });
    }, [props.activity]);

    const resources = useMemo(() => {
        return videos.map((v) => {
            if (v != null) {
                return (
                    <ReactPlayer
                        progressInterval={30_000}
                        config={{
                            file: {
                                attributes: {
                                    controlsList: 'nodownload',
                                },
                            },
                        }}
                        url={v.resource_url}
                        height={'150px'}
                        width={'auto'}
                        controls
                    />
                );
            }
            return null;
        });
    }, [videos]);

    const itemTemplate = (item: Resource) => {
        return (
            <Image
                preview
                src={item?.resource_url}
                alt={item?.resource_name}
                width="auto"
                height={'200px'}
                className="pb-5"
            />
        );
    };

    const thumbnailTemplate = (item: Resource) => {
        return <img src={item.resource_url} alt={item.resource_name} style={{ width: 'auto', height: '70px' }} />;
    };

    const history = useMemo(() => {
        return props.record.answers.find((r) => r.activity_id === props.activity.activity_id);
    }, [props.record, props.activity]);

    return (
        <ActivityContainer className="flex flex-column gap-2" ref={ref}>
            <div className={classNames('he-header--h3 gray-600')}>{props.activity.title}</div>
            <div className={classNames(responsive.isDesktop ? 'quill-custom' : 'quill-custom-mobile', 'mt-2')}>
                <ReadOnlyEditor content={props.activity.question || ''} />
            </div>
            {images.length > 0 && (
                <div className="bg-gray-600 pt-5 mt-3">
                    <Galleria
                        circular
                        responsiveOptions={responsiveGalleriaOptions}
                        value={images}
                        numVisible={5}
                        item={itemTemplate}
                        style={{ height: '100%' }}
                        thumbnail={thumbnailTemplate}
                    />
                </div>
            )}
            {resources.length > 0 && (
                <div>
                    <div className="he-paragraph--medium gray-900 my-4">Ressources vidéos</div>
                    <div className="flex gap-4 flex-wrap">{resources}</div>
                </div>
            )}
            <UCQ
                showTextHelp={false}
                answers={props.activity.answers}
                is_image={false}
                others={{}}
                is_done={props.record.submitted}
                onSelect={(selection) => props.onClick(props.activity, selection)}
                selection={history ? [history.answer] : []}
                showDetailAnswer={1}
                detailAnswer={history?.detail}
                onUpdateAnswerDetail={(detail) => {
                    if (props.onUpdateAnswerDetail) {
                        console.log(detail);
                        props.onUpdateAnswerDetail(props.activity, detail);
                    }
                }}
            />
        </ActivityContainer>
    );
});

const ActivityContainer = styled.div`
    padding: 8px 8px 8px 16px;
    border-left: 4px solid #e0e0e0;
`;
