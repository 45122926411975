import { ScrollContainer } from '@Pages/Home/layouts/components/ScrollContainer';
import { RecordLayout } from './layouts/RecordLayout';
import { RecordActivityPage } from './Record/RecordActivityPage';

export const Record = () => {
    return (
        <ScrollContainer scrollTop>
            <RecordLayout>
                <RecordActivityPage />
            </RecordLayout>
        </ScrollContainer>
    );
};
