import { AuditModule, Module } from '@Types/Module';
import { SessionTimeReport } from '@Types/Session';
import { DurationUnit } from '@Types/Time';
import { AuditRecord } from '@Types/User';

export enum AuditModuleCompletion {
    NotStarted = 0,
    Started = 1 << 0,
    TimeCompleted = 1 << 1,
    ActivitiesCompleted = 1 << 2,
    MinRecordsCreated = 1 << 3,
    MaxRecordsCreated = 1 << 4,
    Submitted = 1 << 5,
}

export enum AuditRecordStatus {
    Started = 1 << 0,
    TimeCompleted = 1 << 1,
    ActivitiesCompleted = 1 << 2,
    Submitted = 1 << 3,
}

export type AuditRecordProgress = {
    [record_id: string]: number;
}

    export const durationToMillis = (qty: number, unit: DurationUnit) => {
        switch (unit) {
            case 'h':
                return qty * 60 * 60 * 1000;
            case 's':
                return qty * 7 * 24 * 60 * 60 * 1000;
            case 'm':
                return qty * 60 * 1000;
            case 'j':
                return qty * 24 * 60 * 60 * 1000;
            default:
                return 0;
        }
    };
export const getRecordsProgress = ({
    module_id,
    report
}: {
    module_id: string;
    report: SessionTimeReport;
}) => {
    
    const moduleReport = report.modules.find((m) => m.module_id === module_id);
    if (!moduleReport || moduleReport.records.length === 0) return {};

    const progress: AuditRecordProgress = {};

    for (const record of moduleReport.records) {
        const recordProgress = record.total;
        progress[record.record_id] = recordProgress;
    }

    return progress;
}


export const getRecordStatus = ({
    record,
    remainingTime,
    module,
}: {
    record: AuditRecord;
    remainingTime: number;
    module: AuditModule;
}) => {
    let status = AuditRecordStatus.Started;

    if (
        module.activities.every(
            (a) => a.activity_id && record.answers.map((ra) => ra.activity_id).includes(a.activity_id)
        )
    ) {
        status |= AuditRecordStatus.ActivitiesCompleted;
    }

    if (remainingTime === 0) {
        status |= AuditRecordStatus.TimeCompleted;
    }

    if (record.submitted) {
        status |= AuditRecordStatus.Submitted;
    }

    return status;
};

export const getAuditModuleProgress = ({
    modules,
    module,
    allRecords,
    progress,
    session_id,
}: {
    modules: Module[];
    module: AuditModule;
    progress: { [record_id: string]: number };
    allRecords: AuditRecord[];
    session_id: string;
}): {
    status: AuditModuleCompletion;
} => {
    let status = AuditModuleCompletion.NotStarted;

    const moduleRecords = allRecords.filter(
        (rec) => rec.module_id === module.module_id && rec.session_id === session_id
    );

    if (moduleRecords.length > 0) {
        status |= AuditModuleCompletion.Started;
    }

    const initialModule = modules.find((mod) => mod.type === 'audit' && mod.audit_type === 'initial');
    const finalModule = modules.find((mod) => mod.type === 'audit' && mod.audit_type === 'final');

    let min = module.min_records;
    let max = module.max_records;

    if (finalModule && finalModule.module_id === module.module_id && initialModule) {
        max = allRecords.filter((r) => r.module_id === initialModule?.module_id && r.session_id === session_id).length;
        min = max;
    }

    if (moduleRecords.length >= min) {
        status |= AuditModuleCompletion.MinRecordsCreated;
    }

    if (moduleRecords.length === max) {
        status |= AuditModuleCompletion.MaxRecordsCreated;
    }

    if (
        moduleRecords.every((r) => {
            const recordProgress = progress[r.record_id];
            if (!recordProgress) return false;
            const status = getRecordStatus({
                record: r,
                remainingTime: recordProgress,
                module,
            });
            return (status & AuditRecordStatus.ActivitiesCompleted) === AuditRecordStatus.ActivitiesCompleted;
        })
    ) {
        status |= AuditModuleCompletion.ActivitiesCompleted;
    }

    return {
        status,
    };
};
