import { AuditModule, AuditModuleCategory } from '@Types/Module';
import { getTextColor } from '@Utils/color.utils';
import { isEqual, sortBy, uniq } from 'lodash';
import { Chart } from 'primereact/chart';
import { memo, useMemo } from 'react';

type Result = {
    module: AuditModule;
    score: {
        [theme: string]: number;
    };
};

type Props = {
    categories: AuditModuleCategory[];
    results: [Result] | [Result, Result];
};
export const AuditGraph = memo(
    (props: Props) => {
        const themes = useMemo(() => {
            return props.results
                .map((result) => result.score)
                .map((result) => Object.keys(result))
                .flat()
                .map((k) => props.categories.find((t) => t.label === k))
                .filter(Boolean) as AuditModuleCategory[];
        }, [props.results, props.categories]);

        const sorted = useMemo(() => {
            return uniq(sortBy(themes, 'label'));
        }, [themes]);

        const chartData = useMemo(() => {
            return {
                datasets: props.results.map((result, i) => {
                    const res = {
                        data: sorted.map((t) => 100 * (result.score[t.label] ?? 0)),
                        borderColor:
                            props.results.length > 1
                                ? i === 0
                                    ? 'rgba(230, 220, 93, 1)'
                                    : 'rgba(112, 158, 226, 1)'
                                : '#5cce5c',
                        label: props.results.length > 1 ? `Résultats ${i === 0 ? 'initiaux' : 'finaux'}` : 'Résultats',
                    };
                    return res;
                }),
                labels: sorted.map((t) => t.label),
            };
        }, [props.results, sorted]);

        return (
            <div className="mx-auto w-full" style={{ maxWidth: 400 }}>
                <Chart
                    type="radar"
                    data={chartData}
                    options={{
                        responsive: true,
                        maintainAspectRatio: true,
                        layout: {
                            padding: {
                                left: 0,
                                right: 0,
                                top: 30,
                                bottom: 0,
                            },
                        },
                        plugins: {
                            legend: {
                                position: 'bottom',
                            },
                        },
                        scales: {
                            r: {
                                beginAtZero: true,
                                min: 0,
                                max: 100,
                                ticks: {
                                    stepSize: 20,
                                    z: 2,
                                },
                                grid: {
                                    z: 1,
                                },
                                pointLabels: {
                                    backdropColor: sorted.map((t) => t.color),
                                    color: sorted.map((t) => getTextColor(t.color)),
                                },
                            },
                        },
                    }}
                />
            </div>
        );
    },
    (prev, next) => {
        return isEqual(prev.results, next.results) && isEqual(prev.categories, next.categories);
    }
);
